import { toast } from "react-toastify";
import { getProfile } from "../crud/auth.crud";

export const getProfileData = async () => {
    const profileResult = await getProfile();

    if (profileResult.data.errorCode) {
        toast.error("Error while fetching profile details");
        return {
            error: true
        };
    }
    if (profileResult.data.data && profileResult.data.data.errorCode) {
        toast.error("Error while fetching profile details");
        return {
            error: true
        };
    }

    if (profileResult.data.data) {
        return {
            error: false,
            profileData: {...profileResult.data.data}
        }
    }
};