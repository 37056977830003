export const API_SUCCESS = {
    UPDATE_PROFILE: "Profile updated successfully",
    ADD_LOCATION: "Location added successfully",
    UPDATE_LOCATION: "Location updated successfully",
    REMOVE_LOCATION: "Location removed successfully",
    REMOVE_IMAGE: "Image removed successfully",
    ADD_IMAGE: "Image added successfully",
    UPDATE_PROFILE_IMAGE: "Profile picture updated successfully",
    UPDATE_BANK: "Bank details updated successfully",
    ADD_SERVICE: "Service added successfully",
    UPDATE_SERVICE: "Service updated successfully",
    REMOVE_SERVICE: "Service removed successfully",
    LOGIN_SUCCESS: "Login successfully",
    BOOKING_SUCCESSFUL: "Booking Successful",
    CANCEL_SUCCESS: "Appointment cancelled successfully",
    COMPLAIN_SUBMITTED: "Complain/ Suggestion submitted successfully",
    FEEDBACK_SUCCESS: "Feedback submitted successfully",
    PROFILE_IMAGE_REMOVED: "Profile photo removed successfully",
    RESCHEDULE_SUCCESS: "Appointment rescheduled successfully",
    FOLLOW_SUCCESS: "Professional followed successfully",
    UNFOLLOW_SUCCESS: "Professional unfollowed successfully",
    FAVOURITE_SUCCESS: "Saved to your favourite list",
    UNFAVOURITE_SUCCESS: "Removed from your favourite list",
    EMAIL_VERIFIED: 'Email verified successfully!',
    EMAIL_SENT: 'Email sent successfully!',
    UPDATE_ANSWER: 'Answer updated successfully!',
    UPDATE_QUESTION: 'Question updated successfully!',
    REMOVE_ANSWER: 'Answer removed successfully!',
    REMOVE_QUESTION: 'Question removed successfully!',
    ACCOUNT_DELETE: 'Account deleted successfully!',
    UPDATE_BILLING_INFO: 'Billing info updated successfully!'
};