import React, { useEffect, useState, useCallback } from 'react';
import "./dashboard.css";
import { shallowEqual, useSelector } from "react-redux";
import * as auth from "../../store/ducks/auth.duck";
import { getBookingDetails, cancelBook, rescheduleBook, joinAppointmentCall } from "../../crud/booking.crud";
import { getAvailability, notification, getTestsAccount, attachAppointmentTest } from "../../crud/home.crud";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import JoinSessionModal from "../../router/join-session-modal";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toast } from 'react-toastify';
import {
    API_ERROR,
    CALL_TIME_PASSED,
    CANCEL_TIME_PASSED,
    RESCHEDULE_TIME_PASSED
} from "../../config/error-message";
import {
    timeArrConst,
    Config,
    monthArr,
    convertTwelveToZeroAM
} from "../../config/constants";
import GalleryModal from "../admins/gallery-modal";
import { API_SUCCESS } from '../../config/success-message';
import CancelModal from "../../router/cancel-modal";
import RescheduleModal from "../../router/reschedule-modal";
import TestListModal from "../admins/test-list-modal";
import ShareToSocialModal from '../admins/shareToSocialModal';
import AddressInfoModal from './address-info-modal';

const defaultImage = require('../../../images/imgAvtr.png');

const timeArr = [...timeArrConst];
let globalConfigData = {};
let taggedAppointmentTests = [];
let isFirstLinkCheck = null;

const Details = (props) => {

    const [selectedProfile, selectProfile] = useState({});
    const [bookingDetails, updateBookingDetails] = useState({});
    const [pageInitialized, updatePageInitialization] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [openGalleryModal, toggleGalleryModal] = useState(false);
    const [currentImageIndex, updateCurrentImageIndex] = useState(-1);
    const [showPrevButtons, togglePrevButton] = useState(false);
    const [showNextButtons, toggleNextButton] = useState(true);
    const [displayCancelModal, toggleCancelModal] = useState(false);
    const [isReschedule, toggleIsReschedule] = useState(false);
    const [datePickerOpened, toggleDatepickerOpened] = useState(false);
    const [selectedDate, handleDateChange] = useState(new Date());
    const [serviceTimeHours, updateServiceTimeHours] = useState([]);
    const [selectedServiceTime, updateSelectedServiceTime] = useState("");
    const [rescheduleBookingObj, updateRescheduleBookingObj] = useState({});
    const [rescheduleModalOpened, toggleRescheduleModal] = useState(false);
    const [completedTests, updateCompletedTests] = useState([]);
    const [testModalOpened, toggleTestModal] = useState(false);
    const [callableSessionObject, updateCallableSessionObject] = useState({});
    const [displayJoinSessionModal, toggleJoinSessionModal] = useState(false);
    const [displayShareModal, toggleShareModal] = useState(false);
    const [addressModalOpened, toggleAddressInfo] = useState(false);
    const { authData } = useSelector(
        ({ auth }) => ({
            authData: auth.user
        }),
        shallowEqual
    );
    const [stars, updateRatingStars] = useState([{
        selected: false
    }, {
        selected: false
    }, {
        selected: false
    }, {
        selected: false
    }, {
        selected: false
    }]);
    // const [globalConfigData, updateGlobalConfig] = useState({});

    const handleNextPrevButtons = () => {
        if (document.getElementsByClassName("categories-wrapper")[0]) {
            if (document.getElementsByClassName("categories-wrapper")[0].clientWidth < document.getElementsByClassName("categories-wrapper")[0].scrollWidth) {
                togglePrevButton(true);
                toggleNextButton(true);

                if (document.getElementsByClassName("categories-wrapper")[0].scrollLeft === 0) {
                    togglePrevButton(false);
                }
                if (Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollWidth - document.getElementsByClassName("categories-wrapper")[0].clientWidth) === Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollLeft)) {
                    toggleNextButton(false);
                }

            } else {
                togglePrevButton(false);
                toggleNextButton(false);
            }
        }
    };

    const sideScroll = (element, direction, speed, distance, step) => {
        let scrollAmount = 0;
        let slideTimer = setInterval(function () {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                handleNextPrevButtons();
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    const nextClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'right', 25, 100, 10);
    };

    const prevClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'left', 25, 100, 10);
    };

    window.onresize = () => {
        handleNextPrevButtons();
    };

    window.onload = () => {
        handleNextPrevButtons();
    };

    const displayNavs = useCallback((bool) => {
        if (bool) {
            handleNextPrevButtons();
        }
    }, []);

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const getDisplayFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();

        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    const getTime = (timeStr, date) => {
        const time = timeStr.slice(0, timeStr.length - 3)
        const amPm = timeStr.slice(timeStr.length - 2)
        let d = new Date();
        if (date) {
            d = new Date(date);
        }
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    };

    const getDuration = (times) => {
        times.sort((a, b) => { return a - b });
        const startTime = convertTwelveToZeroAM(timeArr[times[0] - 1]);
        const endTime = convertTwelveToZeroAM(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0]);
        return `${startTime} - ${endTime}`;
    };

    const checkCurrentDay = (date) => {
        const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        return today === day;
    };

    const checkJoiningCallTime = useCallback((localBookingData) => {
        if (checkCurrentDay(localBookingData.slotDate)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 2] ? timeArr[localBookingData.slots[0] - 2] : timeArr[localBookingData.slots[0] - 1]);
            const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
            return (startTime <= today && endTime > today) && localBookingData.status === Config.Booking.Status.Confirmed;
        }
        return false;
    }, []);

    const checkCancelTime = useCallback((localBookingData) => {
        if (Object.keys(globalConfigData).length) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 1], localBookingData.slotDate);
            const lessThirtyStartTime = new Date(startTime).setMinutes(new Date(startTime).getMinutes() - globalConfigData.customerApp.rescheduledWindow);
            return (lessThirtyStartTime > today) && localBookingData.status === Config.Booking.Status.Confirmed;
        }
    }, []);

    const checkRescheduleTime = useCallback((localBookingData) => {
        if (Object.keys(globalConfigData).length) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 1], localBookingData.slotDate);
            const lessThirtyStartTime = new Date(startTime).setMinutes(new Date(startTime).getMinutes() - globalConfigData.customerApp.noActionWindow);
            return (lessThirtyStartTime > today) && localBookingData.status === Config.Booking.Status.Confirmed;
        }
    }, []);

    const getAllTests = useCallback(async () => {
        toggleDisablePage(true);

        const upcomingBookingResult = await getTestsAccount();

        toggleDisablePage(false);
        if (upcomingBookingResult.data.errorCode) {
            toast.error(API_ERROR[upcomingBookingResult.data.errorCode]);
            return;
        }
        if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.errorCode) {
            toast.error(API_ERROR[upcomingBookingResult.data.data.errorCode]);
            return;
        }

        const localCompletedTests = [];
        let bookingObj = {};

        if (upcomingBookingResult.data && upcomingBookingResult.data.data && upcomingBookingResult.data.data.length) {
            upcomingBookingResult.data.data.forEach(each => {
                bookingObj = {};
                bookingObj.title = each.testRef.title;
                bookingObj.bookingForName = each.bookingForName;
                bookingObj._id = each._id;
                bookingObj.updatedAt = getDisplayFullDateFormat(new Date(each.updatedAt));
                bookingObj.score = each.result.score;

                if (taggedAppointmentTests.includes(each._id)) {
                    bookingObj.selected = true;
                }

                localCompletedTests.push({ ...bookingObj });

            });
        }

        updateCompletedTests([...localCompletedTests]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBookingData = useCallback(async (id) => {
        toggleDisablePage(true);
        const bookingResult = await getBookingDetails(id);
        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }
        const resultData = bookingResult.data.data;

        const localProfessionalObj = {
            name: resultData.vendorRef.personalInfo.name,
            profilePicture: resultData.vendorRef.personalInfo.profilePicture,
            description: resultData.vendorRef.personalInfo.description,
            designation: "",
            bookingId: resultData._id,
            experience: "",
            email: resultData.vendorRef.personalInfo.email,
            websiteUrl: resultData.vendorRef.personalInfo.website,
            addressTitle: resultData.serviceRef.title,
            rating: resultData.ratingDetails && resultData.ratingDetails.rating ? resultData.ratingDetails.rating.toFixed(1) : 0,
            ratingData: resultData.ratingDetails,
            descriptionImages: [],
            services: [{
                location: resultData.serviceRef.location
            }],
            cost: resultData.paymentDetails.serviceFee,
            convenienceFee: resultData.paymentDetails.convenienceFee,
            refundAmount: resultData.paymentDetails.refundAmount ? resultData.paymentDetails.refundAmount : 0,
            gst: resultData.paymentDetails.gst,
            total: resultData.paymentDetails.total,
            slots: resultData.slots,
            status: resultData.status,
            bookingFor: resultData.bookingForName,
            date: resultData.date,
            notes: resultData.notes && resultData.notes.forCustomer ? resultData.notes.forCustomer : "",
            cancelTime: globalConfigData.customerApp.rescheduledWindow,
            taggedTests: resultData.taggedTestReports,
            bookingForType: resultData.bookingForType,
            addressLine1: resultData.serviceRef.addressLine1 || "",
            addressLine2: resultData.serviceRef.addressLine2 || "",
            addressLine3: resultData.serviceRef.addressLine3 || "",
            appointmentBookingType: resultData.appointmentBookingType || 1,
            bookingNumber: resultData.bookingNumber || ""
        };

        if (localProfessionalObj.rating) {
            const localRating = [...stars];
            for (let i = 1; i <= 5; i++) {
                if (i <= localProfessionalObj.ratingData.rating) {
                    localRating[i - 1].selected = true;
                }
            }
            updateRatingStars([...localRating]);
        }

        taggedAppointmentTests = resultData.taggedTestReports;

        handleDateChange(new Date(resultData.date));
        localProfessionalObj.slotDuration = getDuration(resultData.slots);
        localProfessionalObj.slotDate = getFullDateFormat(new Date(resultData.date));
        localProfessionalObj.appointmentNumber = resultData.pin;
        if (resultData.vendorRef.personalInfo.experience.year) {
            const year = resultData.vendorRef.personalInfo.experience.year;
            if (year) {
                localProfessionalObj.experience += `${year} year${year > 1 ? 's' : ''}`;
            }
        }
        if (resultData.vendorRef.photos && resultData.vendorRef.photos.length) {
            resultData.vendorRef.photos.forEach(each => {
                localProfessionalObj.descriptionImages.push(each.url);
            });
        }
        if (resultData.vendorRef.personalInfo.categories && resultData.vendorRef.personalInfo.categories.length) {
            resultData.vendorRef.personalInfo.categories.forEach((item, index) => {
                localProfessionalObj.designation += item.title;
                if (index !== resultData.vendorRef.personalInfo.categories.length - 1) {
                    localProfessionalObj.designation += ", ";
                }
            });
        }
        localProfessionalObj.meetingDetails = {
            platform: resultData.meetingDetails && resultData.meetingDetails.platform ? resultData.meetingDetails.platform : "",
            link: resultData.meetingDetails && resultData.meetingDetails.link ? resultData.meetingDetails.link : ""
        };
        localProfessionalObj.isCancelable = checkCancelTime(localProfessionalObj);
        localProfessionalObj.isCallJoinable = checkJoiningCallTime(localProfessionalObj);
        localProfessionalObj.isReschedulable = checkRescheduleTime(localProfessionalObj);

        selectProfile({ ...localProfessionalObj });
        localProfessionalObj.name = resultData.serviceRef ? resultData.serviceRef.title : "";
        localProfessionalObj.description = resultData.serviceRef ? resultData.serviceRef.description : "";
        localProfessionalObj.serviceId = resultData.serviceRef ? resultData.serviceRef._id : "";
        localProfessionalObj.sessionTime = resultData.serviceRef ? resultData.serviceRef.sessionTime : "";
        updateBookingDetails({ ...localProfessionalObj });
        setTimeout(() => {
            displayNavs(true);
            getAllTests();
        }, 1000);
        if (isFirstLinkCheck) {
            isFirstLinkCheck = false;
            joinTwilioCall({ ...localProfessionalObj });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkCancelTime, checkJoiningCallTime, checkRescheduleTime, displayNavs]);

    const getGlobalData = useCallback(async () => {
        const globalConfigResult = await notification();
        if (globalConfigResult.data.data) {
            globalConfigData = { ...globalConfigResult.data.data };
        }
        if (props.match.params.bookingId) {
            getBookingData(props.match.params.bookingId);
        }

    }, [props.match.params, getBookingData]);

    useEffect(() => {
        if (!pageInitialized) {
            updatePageInitialization(true);
            getGlobalData();
        }
    }, [pageInitialized, getGlobalData]);

    const openMapLocation = () => {
        const coordinates = selectedProfile.services[0].location.coordinates;
        window.open(`https://www.google.com/maps?q=${coordinates[1]},${coordinates[0]}`);
    };

    const openAddressInfo = () => {
        if (selectedProfile.addressLine1 || selectedProfile.addressLine2 || selectedProfile.addressLine3) {
            toggleAddressInfo(true);
        } else {
            openMapLocation();
        }
    };

    const joinTwilioCall = async (localData) => {
        let localBookingData = { ...bookingDetails };
        if (localData && localData.bookingId) {
            localBookingData = { ...localData };
        }
        if (checkJoiningCallTime(localBookingData)) {
            if (!localBookingData.meetingDetails || (localBookingData.meetingDetails && !localBookingData.meetingDetails.link)) {
                if (isFirstLinkCheck === null) {
                    isFirstLinkCheck = true;
                    getBookingData(props.match.params.bookingId);
                    return;
                }
                isFirstLinkCheck = null;
                toast.error('Professional is yet to provide meeting link');
                return;
            }
            toggleJoinSessionModal(true);
            updateCallableSessionObject({
                meetingPlatform: localBookingData.meetingDetails ? localBookingData.meetingDetails.platform : "",
                meetingLink: localBookingData.meetingDetails ? localBookingData.meetingDetails.link : "",
                bookingForType: localBookingData.bookingForType
            });
            const resultData = await joinAppointmentCall(props.match.params.bookingId);
            if (resultData.data.errorCode) {
                toast.error(API_ERROR[resultData.data.errorCode]);
                return;
            }
            if (resultData.data.data && resultData.data.data.errorCode) {
                toast.error(API_ERROR[resultData.data.data.errorCode]);
                return;
            }
        } else {
            toast.error(CALL_TIME_PASSED);
        }
    };

    const cancelBooking = async () => {
        if (checkCancelTime(bookingDetails)) {
            toggleDisablePage(true);
            const cancelBookingResult = await cancelBook(bookingDetails.bookingId);
            toggleDisablePage(false);
            if (cancelBookingResult.data.errorCode) {
                toast.error(API_ERROR[cancelBookingResult.data.errorCode]);
                return;
            }
            if (cancelBookingResult.data.data && cancelBookingResult.data.data.errorCode) {
                toast.error(API_ERROR[cancelBookingResult.data.data.errorCode]);
                return;
            }
            toast.success(API_SUCCESS.CANCEL_SUCCESS);
            getBookingData(bookingDetails.bookingId);
            toggleCancelModal(false);
        } else {
            toast.error(CANCEL_TIME_PASSED);
        }
    };

    const goBack = () => {
        props.history.goBack();
    };

    const chooseGalleryImage = (index) => {
        updateCurrentImageIndex(index);
        toggleGalleryModal(true);
    };

    const openFeedback = () => {
        props.history.push(`/rating-review/${props.match.params.bookingId}`);
    };

    const checkCurrentTime = (times) => {
        const today = new Date().setHours(new Date().getHours() + ((globalConfigData.bufferSlot ? globalConfigData.bufferSlot : 0) * 0.5));
        return getTime(timeArr[times[0] - 1]) > today;
    };

    const getAllSlots = (sessionTime, slots, date) => {
        // need to check if sessionTime (1 hr) is more than slots

        let arr = slots;
        let limit = Number(sessionTime);
        let newArr = [];
        let obj = {};
        for (let i = 0; i < arr.length; i++) {

            if (arr[limit + i - 1]) {
                let arr1 = arr.slice(i, limit + i)
                obj = {};
                if (arr1[arr1.length - 1] - arr1[0] === limit - 1) {
                    obj.value = arr1;
                    if (checkCurrentDay(date)) {
                        if (checkCurrentTime(arr1)) {
                            newArr.push(obj);
                        }
                    } else {
                        newArr.push(obj);
                    }
                }
            }
        }
        return newArr;
    };

    const rescheduleCall = async () => {
        if (!checkRescheduleTime(bookingDetails)) {
            toast.error(RESCHEDULE_TIME_PASSED);

            return;
        }
        const availabilityResult = await getAvailability(bookingDetails.serviceId);
        const localBookingData = {};

        localBookingData.slotDuration = "";
        localBookingData.slotDate = "";
        localBookingData.slots = [];
        let localDateTimeObj = {};

        if (availabilityResult.data.data && availabilityResult.data.data.length) {
            availabilityResult.data.data.forEach(each => {
                let localSlots = getAllSlots(bookingDetails.sessionTime, each.availableSlots, each.date); // sessionTime needed
                if (localSlots.length) {
                    localDateTimeObj = {};
                    localDateTimeObj.date = each.date;
                    localDateTimeObj.availableSlots = localSlots;
                    localBookingData.slots.push({ ...localDateTimeObj });
                }
            });
        }
        if (localBookingData.slots.length) {
            const localAvailableSlots = [];
            if (checkCurrentDay(localBookingData.slots[0].date)) {
                localBookingData.slots[0].availableSlots.forEach(each => {
                    if (checkCurrentTime(each.value)) {
                        localAvailableSlots.push({
                            text: getDuration(each.value),
                            value: JSON.stringify(each.value)
                        });
                    }
                });
                if (localAvailableSlots.length) {
                    localBookingData.slotDate = getFullDateFormat(new Date(localBookingData.slots[0].date));
                    localBookingData.slotDuration = getDuration(localBookingData.slots[0].availableSlots[0].value);
                    handleDateChange(new Date(localBookingData.slots[0].date));
                }
                if (!localAvailableSlots.length && localBookingData.slots.length > 1) {
                    localBookingData.slotDate = getFullDateFormat(new Date(localBookingData.slots[1].date));
                    localBookingData.slotDuration = getDuration(localBookingData.slots[1].availableSlots[0].value);
                    handleDateChange(new Date(localBookingData.slots[1].date));
                    localBookingData.slots[1].availableSlots.forEach(each => {
                        localAvailableSlots.push({
                            text: getDuration(each.value),
                            value: JSON.stringify(each.value)
                        });
                    });
                }
            } else {
                localBookingData.slotDate = getFullDateFormat(new Date(localBookingData.slots[0].date));
                localBookingData.slotDuration = getDuration(localBookingData.slots[0].availableSlots[0].value);
                handleDateChange(new Date(localBookingData.slots[0].date));
                localBookingData.slots[0].availableSlots.forEach(each => {
                    localAvailableSlots.push({
                        text: getDuration(each.value),
                        value: JSON.stringify(each.value)
                    });
                });
            }


            updateServiceTimeHours([...localAvailableSlots]);
            if (localAvailableSlots.length) {
                updateSelectedServiceTime(localAvailableSlots[0].value);
            }

        }

        updateRescheduleBookingObj({ ...localBookingData });
        if (localBookingData.slotDate) {
            toggleIsReschedule(true);
            handleDateChange(new Date(localBookingData.slotDate));
        } else {
            toast.error("No Slots Available to reschedule");
        }
    };

    const disableRandomDates = (date) => {
        if (datePickerOpened) {
            const localBookingData = { ...rescheduleBookingObj };
            const dateArray = [];
            localBookingData.slots.forEach(each => {
                dateArray.push(getFullDateFormat(new Date(each.date)));
            });
            return !dateArray.includes(getFullDateFormat(new Date(date)));
        }
    };

    const changeDate = (date) => {
        handleDateChange(new Date(date));
        const localBookingData = { ...rescheduleBookingObj };
        const localAvailableSlots = [];
        localBookingData.slots.forEach(each => {
            if (getFullDateFormat(new Date(each.date)) === getFullDateFormat(new Date(date))) {
                if (each.availableSlots.length) {
                    each.availableSlots.forEach(each1 => {
                        localAvailableSlots.push({
                            text: getDuration(each1.value),
                            value: JSON.stringify(each1.value)
                        });
                    });
                }

            }
        });

        updateServiceTimeHours([...localAvailableSlots]);
        if (localAvailableSlots.length) {
            updateSelectedServiceTime(localAvailableSlots[0].value);
        }
    };

    const selectServiceTime = (e) => {
        updateSelectedServiceTime(e.target.value);
    };

    const submitReschedule = async () => {
        if (!checkRescheduleTime(bookingDetails)) {
            toast.error(RESCHEDULE_TIME_PASSED);

            return;
        }

        toggleDisablePage(true);
        const rescheduleBookingResult = await rescheduleBook(bookingDetails.bookingId, {
            date: new Date(new Date(new Date(new Date(new Date(getFullDateFormat(new Date(selectedDate))).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString(),
            slots: JSON.parse(selectedServiceTime)
        });
        toggleDisablePage(false);
        if (rescheduleBookingResult.data.errorCode) {
            toast.error(API_ERROR[rescheduleBookingResult.data.errorCode]);
            return;
        }
        if (rescheduleBookingResult.data.data && rescheduleBookingResult.data.data.errorCode) {
            toast.error(API_ERROR[rescheduleBookingResult.data.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.RESCHEDULE_SUCCESS);
        getBookingData(bookingDetails.bookingId);
        toggleRescheduleModal(false);
        toggleIsReschedule(false);
    };

    const submitTests = async () => {
        const localCompletedTestIds = [];
        completedTests.forEach(each => {
            if (each.selected) {
                localCompletedTestIds.push(each._id);
            }
        });
        toggleDisablePage(true);
        const submitTestsAttachments = await attachAppointmentTest({ testBookingIds: localCompletedTestIds }, props.match.params.bookingId);
        toggleDisablePage(false);

        if (submitTestsAttachments.data.errorCode) {
            toast.error(API_ERROR[submitTestsAttachments.data.errorCode]);
            return;
        }
        if (submitTestsAttachments.data.data && submitTestsAttachments.data.data.errorCode) {
            toast.error(API_ERROR[submitTestsAttachments.data.data.errorCode]);
            return;
        }

        getBookingData(props.match.params.bookingId);
        toggleTestModal(false);
    };

    const chooseTest = (localTest) => {
        const localCompletedTests = [...completedTests];
        localCompletedTests.forEach(each => {
            if (each._id === localTest._id) {
                each.selected = !each.selected;
            }
        });
        updateCompletedTests([...localCompletedTests]);
    };

    const closeTestModal = () => {
        const taggedTestList = taggedAppointmentTests.map((e) => e._id);
        toggleTestModal(false);
        const localCompletedTests = [...completedTests];
        localCompletedTests.forEach(each => {
            if (taggedTestList.includes(each._id)) {
                each.selected = true;
            } else {
                each.selected = false;
            }
        });
        updateCompletedTests([...localCompletedTests]);
    };

    const openTestModal = () => {
        const taggedTestList = taggedAppointmentTests.map((e) => e._id);
        let localCompletedTests = [...completedTests];
        updateCompletedTests([...localCompletedTests.map(each => {
            each.selected = taggedTestList.includes(each._id);
            return each;
        })]);
        toggleTestModal(true);
    };

    const timeConvert = (n) => {
        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return `${rhours ? ` ${rhours} hour${rhours > 1 ? 's' : ''}` : ''} ${rminutes ? ` ${rminutes} min${rminutes > 1 ? 's' : ''}` : ''}`;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={`row dashboard ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
                {addressModalOpened ?
                    <AddressInfoModal closeModal={toggleAddressInfo}
                        openMapLocation={openMapLocation}
                        displayModal={addressModalOpened}
                        selectedProfile={selectedProfile} />
                    : null}
                <CancelModal displayCancelModal={displayCancelModal}
                    toggleCancelModal={toggleCancelModal} cancelBooking={cancelBooking} />
                <RescheduleModal rescheduleModalOpened={rescheduleModalOpened}
                    toggleRescheduleModal={toggleRescheduleModal} submitReschedule={submitReschedule} />
                <TestListModal displayModal={testModalOpened}
                    toggleTestModal={closeTestModal} submitTests={submitTests} tests={completedTests} chooseTest={chooseTest} taggedTests={taggedAppointmentTests}></TestListModal>
                {displayJoinSessionModal ?
                    <JoinSessionModal displayJoinSessionModal={displayJoinSessionModal}
                        toggleJoinSessionModal={toggleJoinSessionModal} callableSessionObject={callableSessionObject} joiningType="appointment" openSharePopup={toggleShareModal} /> : null}
                {Object.keys(bookingDetails).length && displayShareModal ?
                    <ShareToSocialModal displayShareModal={displayShareModal}
                        toggleShareModal={toggleShareModal} bookingDetails={bookingDetails} shareType="appointment" /> : null}
                {selectedProfile.name ?
                    <div className="col-lg-12">
                        <div className="col-12 categories-heading">
                            <h4 className={`mb-0`}><i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: "10px", cursor: "pointer" }} onClick={goBack}></i>Appointment Details</h4>
                        </div>
                        <div className="col-12">
                            <div className="row profile-details-wrapper" style={{ margin: 0 }}>
                                <div className="col-12 p-0">
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className={` ${selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ? "col-md-5 col-xs-12" : "col-12 no-border"} pr-20 left-booking-item`}>
                                            <div className="row m-0">
                                                <div className="profile-image-wrapper p-0">
                                                    {selectedProfile.profilePicture ?
                                                        <div style={{ backgroundImage: "url(" + selectedProfile.profilePicture + ")" }} className="profile-image" /> :
                                                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="profile-image" />}
                                                </div>
                                                <div className="profile-other-details profile-name-wrapper">
                                                    <div className="profile-name">{selectedProfile.name}</div>
                                                    <div className="profile-designation">{selectedProfile.designation}</div>
                                                    <div className="profile-address-title">{selectedProfile.addressTitle}</div>
                                                    <div className="profile-icon-wrapper">
                                                        {/* {selectedProfile.email ?
                                                            <div className="tooltip">
                                                                <i className="fa fa-envelope" aria-hidden="true" onClick={copyEmailToClipboard} onMouseLeave={emailMouseOut}>

                                                                </i>
                                                                <span className="tooltiptext" id="myTooltip">Copy to clipboard</span>
                                                            </div> : null} */}
                                                        {selectedProfile.websiteUrl ?
                                                            <img src={require('../../../images/globe.svg')} alt="website" onClick={() => window.open(selectedProfile.websiteUrl)} /> : null}
                                                        {selectedProfile && selectedProfile.services && selectedProfile.services.length ?
                                                            <img src={require('../../../images/location.svg')} alt="location" onClick={openAddressInfo} /> : null}
                                                    </div>
                                                    <div className="profile-exp">Experience - {selectedProfile.experience}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                                            <div className="col-md-7 col-xs-12 pl-20 pr-0 booking-gallery">

                                                <div className="row m-0">
                                                    <div className="col-7 pl-0 profile-name">Gallery</div>
                                                    {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                                                        <div className="col-5 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                                            <button className="prev-button-wrapper" onClick={prevClick} id="slideprev" disabled={!showPrevButtons}>
                                                                <i className="fas fa-arrow-left"></i>
                                                            </button>
                                                            <button className="next-button-wrapper" onClick={nextClick} id="slide" disabled={!showNextButtons}>
                                                                <i className="fas fa-arrow-right"></i>
                                                            </button>
                                                        </div> : null}
                                                    <div className={`categories-wrapper pl-0 pr-0 pt-2 pb-0`} style={{ margin: 0 }}>
                                                        {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                                                            selectedProfile.descriptionImages.map((image, index) => {
                                                                return <div className={`about-image-wrapper pl-0`} key={index} onClick={() => chooseGalleryImage(index)}>
                                                                    <div className="profile-image description-image" style={{ backgroundImage: "url(" + image + ")" }} />
                                                                </div>
                                                            })
                                                            : <div className="text-center well col-12 color-black">
                                                                No Images Available!
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                                <div className="col-12 p-0">
                                    {Object.keys(bookingDetails).length ?
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-12 pl-0 pr-0 service-details-wrapper">
                                                <div className="row">
                                                    <h5 className="col-12">
                                                        Service Provided
                                                    </h5>
                                                    <div className="col-md-8 col-sm-12">
                                                        <div className="profile-name" style={{ fontSize: "15px" }}>{bookingDetails.name}</div>
                                                        <div className="profile-service-desc">{bookingDetails.description}</div>
                                                        <div className="profile-date-time row ml-0 mr-0 mb-3">
                                                            <div className="col-md-4 col-6 pl-0">
                                                                <div className="label">Selected date</div>
                                                                <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {getDisplayFullDateFormat(new Date(bookingDetails.slotDate))}</div>
                                                            </div>
                                                            <div className="col-md-4 col-6 pl-0">
                                                                <div className="label">Time slot</div>
                                                                <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {bookingDetails.slotDuration}</div>
                                                            </div>
                                                        </div>
                                                        {bookingDetails.bookingNumber ?
                                                            <div className="profile-date-time row ml-0 mr-0 mb-3">
                                                                <div className="col-12 pl-0">
                                                                    <div className="label">Booking Number</div>
                                                                    <div>{bookingDetails.bookingNumber}</div>
                                                                </div>
                                                            </div> : null}
                                                        <div className="w-100">
                                                            <div className="fee-text bor-right pl-0">Service Fee: <i className="fas fa-rupee-sign"></i>{bookingDetails.cost.toFixed(2)}</div>
                                                            <div className="fee-text bor-right">Convenience Fee: <i className="fas fa-rupee-sign"></i>{bookingDetails.convenienceFee.toFixed(2)}</div>
                                                            <div className="fee-text bor-right">GST: <i className="fas fa-rupee-sign"></i>{bookingDetails.gst.toFixed(2)}</div>
                                                            <div className="fee-text">Total: <i className="fas fa-rupee-sign"></i>{bookingDetails.total.toFixed(2)}</div>
                                                        </div>
                                                        <div className="profile-amount row ml-0 mr-0">
                                                            Status: {Config.Booking.NumberStatus[selectedProfile.status]}
                                                        </div>
                                                        {bookingDetails.appointmentBookingType === 1 ?
                                                            <div className="fee-text pl-0 row ml-0 mr-0 mt-1 mb-2">Video Consultation</div> :
                                                            <div className="fee-text pl-0 row ml-0 mr-0 mt-1">Clinic Visit</div>}

                                                        {bookingDetails?.meetingDetails?.platform ?
                                                            <div className="profile-amount pl-0 row ml-0 mr-0">
                                                                Meeting Platform: {bookingDetails?.meetingDetails?.platform}
                                                            </div> : null}
                                                    </div>
                                                    {selectedProfile.status === Config.Booking.Status.Confirmed || selectedProfile.status === Config.Booking.Status.Completed ?
                                                        <div className='col-md-4 col-sm-12 test-border-768' style={{ paddingLeft: 16 }}>
                                                            <p style={{ fontSize: 16, fontWeight: 600, color: '#374561', fontFamily: 'Poppins Medium' }}>Assessments Results <button style={{ float: 'right' }} type="button" className="btn btn-primary test-button" onClick={openTestModal}>Add</button></p>
                                                            <p style={{ fontSize: 12, color: '#566986', fontFamily: 'Poppins Regular', marginBottom: 0 }}>{selectedProfile.taggedTests.length} Assessment{selectedProfile.taggedTests.length > 1 ? 's' : null} attached</p>
                                                            {bookingDetails.appointmentBookingType === 2 && (bookingDetails.addressLine1 || bookingDetails.addressLine2 || bookingDetails.addressLine3) ?
                                                                <div className='mt-4'>
                                                                    <p style={{ fontSize: 16, fontWeight: 600, color: '#374561', fontFamily: 'Poppins Medium' }}>Address <button type="button" style={{
                                                                        borderRadius: "5px",
                                                                        padding: "5px",
                                                                        fontSize: "12px",
                                                                        float: "right"
                                                                    }} className="btn btn-outline-primary" onClick={openMapLocation}>View on map</button></p>

                                                                    <div className='mt-2'>
                                                                        {bookingDetails.addressLine1 ? `${bookingDetails.addressLine1}, ` : ''}{bookingDetails.addressLine2 ? `${bookingDetails.addressLine2} ` : ''}
                                                                        {bookingDetails.addressLine3 ?
                                                                            <p><b>Direction to reach: </b> {bookingDetails.addressLine3}</p> : null}
                                                                    </div>
                                                                </div> : null}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="col-12 pl-0 pr-0 pt-20 pos-relative">

                                                {!isReschedule ?
                                                    <div className="row text-center" style={{ margin: "0 0 15px 0" }}>
                                                        {selectedProfile.status === Config.Booking.Status.Confirmed ? <>
                                                            <div className="col-12 p-0 booking-details-header">
                                                                Appointment Number: {bookingDetails.appointmentNumber}
                                                            </div>

                                                            <div className="col-12 booking-details-change-button  appointment-details-button-wrapper">
                                                                {bookingDetails.appointmentBookingType === 1 ?
                                                                    <button type="button" className="btn btn-primary" disabled={!bookingDetails.isCallJoinable} onClick={joinTwilioCall}>Join</button> :
                                                                    <div className="col-12 p-0 mb-2">
                                                                        This is a clinic visit appointment, please be at the service location before the appointment starts
                                                                    </div>
                                                                }
                                                                {bookingDetails.isReschedulable ?
                                                                    <button type="button" className="btn btn-primary reschedule-button" onClick={rescheduleCall}>Reschedule</button> : null}
                                                                {bookingDetails.isCancelable ?
                                                                    <>
                                                                        <button type="button" className="btn btn-outline-primary" onClick={() => toggleCancelModal(true)}>Cancel</button>
                                                                        <div className="pt-2" style={{ fontSize: "12px" }}>*Cancel Before {timeConvert(bookingDetails.cancelTime)}</div>
                                                                    </> : null}
                                                            </div> </> : null}
                                                        {selectedProfile.status === Config.Booking.Status.Completed ?
                                                            <div className={`${!selectedProfile.rating ? "col-12" : "col-md-6 booking-status-wrapper"}  pl-0 booking-details-header`}>
                                                                The appointment has been {selectedProfile.rating ? <br /> : null}completed
                                                            </div> : null}
                                                        {selectedProfile.status === Config.Booking.Status.Completed ? <>
                                                            <div className={`${!selectedProfile.rating ? "col-12" : "col-md-6 p-0"} booking-details-change-button  appointment-details-button-wrapper`}>
                                                                {selectedProfile.status === Config.Booking.Status.Completed ? !selectedProfile.rating ?
                                                                    <button type="button" className="btn btn-primary give-feedback-button" onClick={openFeedback}>Rate & Feedback</button> :
                                                                    <div className="review-section">
                                                                        <div className="review-image-wrapper p-0">
                                                                            {authData && authData.personalInfo && authData.personalInfo.profilePicture ?
                                                                                <div style={{ backgroundImage: "url(" + authData.personalInfo.profilePicture + ")" }} className="review-image" /> :
                                                                                <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="review-image" />}
                                                                        </div>
                                                                        <div className="review-other-details profile-name-wrapper">
                                                                            <div className="profile-name">{authData && authData.personalInfo && authData.personalInfo.name}</div>
                                                                            <div className="profile-designation">{getDisplayFullDateFormat(new Date(selectedProfile.ratingData.dateTime))}</div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className='review-text-wrapper text-left'>
                                                                            {selectedProfile.ratingData.rating ?
                                                                                stars.map((star, starIndex) => {
                                                                                    return <i key={starIndex} className={`fa-star fas`} style={{ color: star.selected ? "#E12936" : "#ccc" }}></i>
                                                                                }) : null}
                                                                                {bookingDetails.ratingData.feedback ?
                                                                            <div>{selectedProfile.ratingData.feedback.split('\n').map((item, key) => {
                                                                                return <span key={key}>{item}<br /></span>
                                                                            })}</div> : null}
                                                                        </div>
                                                                    </div> : null}
                                                            </div> </> : null}

                                                        {selectedProfile.status === Config.Booking.Status.Failed ?
                                                            <div className="col-12 p-0 booking-details-header">
                                                                The appointment has been failed
                                                            </div> : null}
                                                        {selectedProfile.status === Config.Booking.Status.CanceledByCustomer ?
                                                            <div className="col-12 p-0 booking-details-header">
                                                                The appointment has been cancelled by you
                                                                <div className="cancel-info">
                                                                    You are eligible for a refund of <i className="fas fa-rupee-sign"></i>{selectedProfile.refundAmount.toFixed(2)}
                                                                </div>
                                                                <div className="cancel-info">
                                                                    The amount will be refunded in 7 working days.
                                                                </div>
                                                            </div> : null}
                                                        {selectedProfile.status === Config.Booking.Status.CanceledByVendor ?
                                                            <div className="col-12 p-0 booking-details-header">
                                                                The appointment has been cancelled by the professional
                                                                <div className="cancel-info">
                                                                    You are eligible for a refund of <i className="fas fa-rupee-sign"></i>{selectedProfile.refundAmount.toFixed(2)}
                                                                </div>
                                                                <div className="cancel-info">
                                                                    The amount will be refunded in 7 working days.
                                                                </div>
                                                            </div> : null}
                                                        {selectedProfile.status === Config.Booking.Status.Timedout ?
                                                            <div className="col-12 p-0 booking-details-header">
                                                                The appointment has been timed out
                                                                <div className="cancel-info">
                                                                    You are not eligible for a refund.
                                                                </div>
                                                            </div> : null}
                                                    </div>
                                                    : null}
                                                {isReschedule ?
                                                    <div className="row" style={{ margin: "0 0 15px 0" }}>
                                                        <h4 className="col-sm-8 col-xs-12 pl-0">Reschedule appointment</h4>
                                                        <div className="col-sm-4 col-xs-12 pr-0 text-right pb-3 back-button-wrapper">
                                                            <button type="button" className="btn btn-outline-primary" onClick={() => toggleIsReschedule(false)}>Back</button>
                                                        </div>
                                                        <div className="col-md-6 col-xs-12 slot-wrapper white-bg" style={{ margin: "0 auto" }}>
                                                            {bookingDetails.slotDate ? <div className="row m-0">
                                                                <div className="col-12 p-0">
                                                                    <div className="service-list-name">{bookingDetails.name}</div>
                                                                </div>
                                                                <div className="col-12 p-0" style={{ margin: "5px 0" }}>
                                                                    <div className="service-name">{bookingDetails.description}</div>
                                                                </div>
                                                                <h6 className="col-12 p-0">Fill up the following form to complete booking</h6>
                                                                <div className="col-sm-6 col-xs-12 pl-0 mb-3 mt-3">
                                                                    <label for="exampleFormControlInput1" className="form-label mb-0 display-block">Select date</label>
                                                                    <DatePicker value={selectedDate} onChange={changeDate} format="yyyy-MM-dd" minDate={new Date()} shouldDisableDate={disableRandomDates} onOpen={() => toggleDatepickerOpened(true)} onClose={() => toggleDatepickerOpened(false)} />
                                                                </div>

                                                                <div className="col-sm-6 col-xs-12 pl-0 mb-3 mt-3">
                                                                    <label for="exampleFormControlInput1" className="form-label">Select Time</label>
                                                                    {serviceTimeHours.length ?
                                                                        <select className="form-select form-control" aria-label="Service Time" value={selectedServiceTime} onChange={selectServiceTime}>
                                                                            {serviceTimeHours.map((loc, index) => {
                                                                                return <option key={index} value={loc.value}>{loc.text}</option>
                                                                            })}
                                                                        </select> : <div className="text-center well col-12 color-black pt-3">
                                                                            No Slots Available!
                                                                        </div>}
                                                                </div>

                                                                <div className="col-12 booking-details-change-button pay-now-button">
                                                                    <button type="button" className="btn btn-primary" onClick={() => toggleRescheduleModal(true)}>Submit</button>
                                                                </div>
                                                            </div> : null}

                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                {selectedProfile.notes && !isReschedule ?
                                    <div className="col-12 pl-0 pr-0 notes-wrapper">
                                        <div className="row m-0">
                                            <div className="col-12 p-0 text-left">
                                                <label className="label">Notes from professional</label>
                                                <div className="notes">{selectedProfile.notes}</div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                    : <div className="text-center well col-12 color-black pt-3">
                        loading...
                    </div>}
            </div>
            {selectedProfile.descriptionImages && openGalleryModal ?
                <GalleryModal openGalleryModal={openGalleryModal} galleryImages={selectedProfile.descriptionImages} currentImageIndex={currentImageIndex} toggleGalleryModal={toggleGalleryModal} updateCurrentImageIndex={updateCurrentImageIndex} /> : null}
        </MuiPickersUtilsProvider>
    )
};

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Details)
);