import React, { useEffect, useState, useCallback } from 'react';
import "./dashboard.css";
import { shallowEqual, useSelector } from "react-redux";
import * as auth from "../../store/ducks/auth.duck";
import { getEventBookingDetails, joinEventCall } from "../../crud/booking.crud";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toast } from 'react-toastify';
import {
    API_ERROR,
    CALL_TIME_PASSED
} from "../../config/error-message";
import {
    timeArrConst,
    Config,
    monthArr,
    convertTwelveToZeroAM
} from "../../config/constants";
import GalleryModal from "../admins/gallery-modal";
// import { API_SUCCESS } from '../../config/success-message';

const defaultImage = require('../../../images/imgAvtr.png');

const timeArr = [...timeArrConst];

const Details = (props) => {

    const [selectedProfile, selectProfile] = useState({});
    const [bookingDetails, updateBookingDetails] = useState({});
    const [pageInitialized, updatePageInitialization] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [openGalleryModal, toggleGalleryModal] = useState(false);
    const [currentImageIndex, updateCurrentImageIndex] = useState(-1);
    const [showPrevButtons, togglePrevButton] = useState(false);
    const [showNextButtons, toggleNextButton] = useState(true);
    const { authData } = useSelector(
        ({ auth }) => ({
            authData: auth.user
        }),
        shallowEqual
    );
    const [stars, updateRatingStars] = useState([{
        selected: false
    }, {
        selected: false
    }, {
        selected: false
    }, {
        selected: false
    }, {
        selected: false
    }]);

    const handleNextPrevButtons = () => {
        if (document.getElementsByClassName("categories-wrapper")[0]) {
            if (document.getElementsByClassName("categories-wrapper")[0].clientWidth < document.getElementsByClassName("categories-wrapper")[0].scrollWidth) {
                togglePrevButton(true);
                toggleNextButton(true);

                if (document.getElementsByClassName("categories-wrapper")[0].scrollLeft === 0) {
                    togglePrevButton(false);
                }
                if (Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollWidth - document.getElementsByClassName("categories-wrapper")[0].clientWidth) === Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollLeft)) {
                    toggleNextButton(false);
                }

            } else {
                togglePrevButton(false);
                toggleNextButton(false);
            }
        }
    };

    const sideScroll = (element, direction, speed, distance, step) => {
        let scrollAmount = 0;
        let slideTimer = setInterval(function () {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                handleNextPrevButtons();
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    const nextClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'right', 25, 100, 10);
    };

    const prevClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'left', 25, 100, 10);
    };

    window.onresize = () => {
        handleNextPrevButtons();
    };

    window.onload = () => {
        handleNextPrevButtons();
    };

    const displayNavs = useCallback((bool) => {
        if (bool) {
            handleNextPrevButtons();
        }
    }, []);

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const getDisplayFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();

        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    const getTime = (timeStr, date) => {
        const time = timeStr.slice(0, timeStr.length - 3)
        const amPm = timeStr.slice(timeStr.length - 2)
        let d = new Date();
        if (date) {
            d = new Date(date);
        }
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    };

    const getDuration = (times) => {
        times.sort((a, b) => { return a - b });
        const startTime = convertTwelveToZeroAM(timeArr[times[0] - 1]);
        return `${startTime}`;
    };

    const checkCurrentDay = (date) => {
        const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        return today === day;
    };

    const checkJoiningCallTime = useCallback((localBookingData) => {
        if (checkCurrentDay(localBookingData.slotDate)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 2] ? timeArr[localBookingData.slots[0] - 2] : timeArr[localBookingData.slots[0] - 1]);
            const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
            return (startTime <= today && endTime > today) && localBookingData.status === Config.Event.Status.Confirmed;
        }
        return false;
    }, []);

    const getBookingData = useCallback(async (id) => {
        toggleDisablePage(true);
        const bookingResult = await getEventBookingDetails(id);
        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }
        const resultData = bookingResult.data.data;

        const localProfessionalObj = {
            name: resultData.vendorRef.personalInfo.name,
            profilePicture: resultData.vendorRef.personalInfo.profilePicture,
            description: resultData.vendorRef.personalInfo.description,
            designation: "",
            bookingId: resultData._id,
            experience: "",
            email: resultData.vendorRef.personalInfo.email,
            websiteUrl: resultData.vendorRef.personalInfo.website,
            addressTitle: resultData.eventRef.title,
            rating: resultData.ratingDetails && resultData.ratingDetails.rating ? resultData.ratingDetails.rating.toFixed(1) : 0,
            ratingData: resultData.ratingDetails,
            descriptionImages: [],
            cost: resultData.paymentDetails.serviceFee,
            convenienceFee: resultData.paymentDetails.convenienceFee,
            refundAmount: resultData.paymentDetails.refundAmount ? resultData.paymentDetails.refundAmount : 0,
            gst: resultData.paymentDetails.gst,
            total: resultData.paymentDetails.total,
            slots: resultData.eventRef.slots,
            status: resultData.status,
            bookingFor: resultData.bookingForName,
            date: resultData.date,
            eventLink: resultData.eventRef.eventLink,
            bookingNumber: resultData.bookingNumber || ""
        };
        if (localProfessionalObj.rating) {
            const localRating = [...stars];
            for (let i = 1; i <= 5; i++) {
                if (i <= localProfessionalObj.ratingData.rating) {
                    localRating[i - 1].selected = true;
                }
            }
            updateRatingStars([...localRating]);
        }

        localProfessionalObj.slotDuration = getDuration(resultData.eventRef.slots);
        localProfessionalObj.slotDate = getFullDateFormat(new Date(resultData.date));
        localProfessionalObj.appointmentNumber = resultData.pin;
        if (resultData.vendorRef.personalInfo.experience.year) {
            const year = resultData.vendorRef.personalInfo.experience.year;
            if (year) {
                localProfessionalObj.experience += `${year} year${year > 1 ? 's' : ''}`;
            }
        }
        if (resultData.eventRef.images && resultData.eventRef.images.length) {
            resultData.eventRef.images.forEach(each => {
                localProfessionalObj.descriptionImages.push(each.url);
            });
        }
        if (resultData.vendorRef.personalInfo.categories && resultData.vendorRef.personalInfo.categories.length) {
            resultData.vendorRef.personalInfo.categories.forEach((item, index) => {
                localProfessionalObj.designation += item.title;
                if (index !== resultData.vendorRef.personalInfo.categories.length - 1) {
                    localProfessionalObj.designation += ", ";
                }
            });
        }
        localProfessionalObj.isCallJoinable = checkJoiningCallTime(localProfessionalObj);

        selectProfile({ ...localProfessionalObj });
        localProfessionalObj.name = resultData.eventRef ? resultData.eventRef.title : "";
        localProfessionalObj.description = resultData.eventRef ? resultData.eventRef.description : "";
        localProfessionalObj.serviceId = resultData.eventRef ? resultData.eventRef._id : "";
        localProfessionalObj.sessionTime = resultData.eventRef ? resultData.eventRef.sessionTime : "";
        updateBookingDetails({ ...localProfessionalObj });
        setTimeout(() => {
            displayNavs(true);
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkJoiningCallTime, displayNavs]);

    const getGlobalData = useCallback(async () => {
        if (props.match.params.bookingId) {
            getBookingData(props.match.params.bookingId);
        }
        
    }, [props.match.params, getBookingData]);

    useEffect(() => {
        if (!pageInitialized) {
            updatePageInitialization(true);
            getGlobalData();
        }
    }, [pageInitialized, getGlobalData]);

    const joinTwilioCall = async () => {
        if (checkJoiningCallTime(bookingDetails)) {
            window.open(bookingDetails.eventLink);
            const resultData = await joinEventCall(bookingDetails.bookingId);
            if (resultData.data.errorCode) {
                toast.error(API_ERROR[resultData.data.errorCode]);
                return;
            }
            if (resultData.data.data && resultData.data.data.errorCode) {
                toast.error(API_ERROR[resultData.data.data.errorCode]);
                return;
            }
        } else {
            toast.error(CALL_TIME_PASSED);
        }
    };

    const goBack = () => {
        props.history.goBack();
    };

    const chooseGalleryImage = (index) => {
        updateCurrentImageIndex(index);
        toggleGalleryModal(true);
    };

    const openFeedback = () => {
        props.history.push(`/online-event-rating-review/${props.match.params.bookingId}`);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={`row dashboard ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
                {selectedProfile.name ?
                    <div className="col-lg-12">
                        <div className="col-12 categories-heading">
                            <h4 className={`mb-0`}><i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: "10px", cursor: "pointer" }} onClick={goBack}></i>Event Booking Details</h4>
                        </div>
                        <div className="col-12">
                            <div className="row profile-details-wrapper" style={{ margin: 0 }}>
                                <div className="col-12 p-0">
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className="col-md-5 col-xs-12 pr-20 left-booking-item">
                                            <div className="row m-0">
                                                <div className="profile-image-wrapper p-0">
                                                    {selectedProfile.profilePicture ?
                                                        <div style={{ backgroundImage: "url(" + selectedProfile.profilePicture + ")" }} className="profile-image" /> :
                                                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="profile-image" />}
                                                </div>
                                                <div className="profile-other-details profile-name-wrapper">
                                                    <div className="profile-name">{selectedProfile.name}</div>
                                                    <div className="profile-designation">{selectedProfile.designation}</div>
                                                    <div className="profile-address-title">{selectedProfile.addressTitle}</div>
                                                    <div className="profile-exp">Experience - {selectedProfile.experience}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-xs-12 pl-20 pr-0 booking-gallery">
                                            <div className="row m-0">
                                                <div className="col-7 pl-0 profile-name">Event Images</div>
                                                {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                                                    <div className="col-5 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                                        <button className="prev-button-wrapper" onClick={prevClick} id="slideprev" disabled={!showPrevButtons}>
                                                            <i className="fas fa-arrow-left"></i>
                                                        </button>
                                                        <button className="next-button-wrapper" onClick={nextClick} id="slide" disabled={!showNextButtons}>
                                                            <i className="fas fa-arrow-right"></i>
                                                        </button>
                                                    </div> : null}
                                                <div className={`categories-wrapper pl-0 pr-0 pt-2 pb-0`} style={{ margin: 0 }}>
                                                    {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                                                        selectedProfile.descriptionImages.map((image, index) => {
                                                            return <div className={`about-image-wrapper pl-0`} key={index} onClick={() => chooseGalleryImage(index)}>
                                                                <div className="profile-image description-image" style={{ backgroundImage: "url(" + image + ")" }} />
                                                            </div>
                                                        })
                                                        : <div className="text-center well col-12 color-black">
                                                            No Images Available!
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 p-0">
                                    {Object.keys(bookingDetails).length ?
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-12 pl-0 pr-0 service-details-wrapper">
                                                <div className="profile-name">{bookingDetails.name}</div>
                                                <div className="profile-service-desc">{bookingDetails.description}</div>
                                                <div className="profile-date-time row ml-0 mr-0 mb-3">
                                                    <div className="col-md-4 col-6 pl-0">
                                                        <div className="label">Selected date</div>
                                                        <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {getDisplayFullDateFormat(new Date(bookingDetails.slotDate))}</div>
                                                    </div>
                                                    <div className="col-md-4 col-6 pl-0">
                                                        <div className="label">Time slot</div>
                                                        <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {bookingDetails.slotDuration}</div>
                                                    </div>
                                                </div>
                                                {bookingDetails.bookingNumber ? 
                                                <div className="profile-date-time row ml-0 mr-0 mb-3">
                                                    <div className="col-md-4 col-6 pl-0">
                                                        <div className="label">Booking Number</div>
                                                        <div>{bookingDetails.bookingNumber}</div>
                                                    </div>
                                                </div> : null}
                                                <div className="w-100">
                                                    <div className="fee-text bor-right pl-0">Service Fee: <i className="fas fa-rupee-sign"></i>{bookingDetails.cost.toFixed(2)}</div>
                                                    <div className="fee-text bor-right">Convenience Fee: <i className="fas fa-rupee-sign"></i>{bookingDetails.convenienceFee.toFixed(2)}</div>
                                                    <div className="fee-text bor-right">GST: <i className="fas fa-rupee-sign"></i>{bookingDetails.gst.toFixed(2)}</div>
                                                    <div className="fee-text">Total: <i className="fas fa-rupee-sign"></i>{bookingDetails.total.toFixed(2)}</div>
                                                </div>
                                                <div className="profile-amount row ml-0 mr-0">
                                                    Status: {Config.Event.NumberStatus[selectedProfile.status]}
                                                </div>
                                            </div>
                                            <div className="col-12 pl-0 pr-0 pt-20">
                                                    <div className="row text-center" style={{ margin: "0 0 15px 0" }}>
                                                        {selectedProfile.status === Config.Event.Status.Confirmed ? <>
                                                        <div className="col-12 booking-details-change-button  appointment-details-button-wrapper">
                                                            <button type="button" className="btn btn-primary" onClick={joinTwilioCall} disabled={!bookingDetails.isCallJoinable}>Join</button>
                                                        </div> </> : null}
                                                        {selectedProfile.status === Config.Event.Status.Timedout ? <>
                                                            <div className="col-12 booking-details-change-button  appointment-details-button-wrapper">
                                                                <button type="button" disabled className="btn btn-primary" >Join</button>
                                                            </div> </> : null}
                                                            {selectedProfile.status === Config.Event.Status.Completed ?
                                                            <div className={`${!selectedProfile.rating ? "col-12" : "col-md-6 booking-status-wrapper"}  pl-0 booking-details-header`}>
                                                                The event has been {selectedProfile.rating ? <br /> : null}completed
                                                            </div> : null}
                                                        {selectedProfile.status === Config.Event.Status.Completed ? <>
                                                            <div className={`${!selectedProfile.rating ? "col-12" : "col-md-6 p-0"} booking-details-change-button  appointment-details-button-wrapper`}> 
                                                            {!selectedProfile.rating ?
                                                                    <button type="button" className="btn btn-primary give-feedback-button" onClick={openFeedback}>Rate & Feedback</button> :
                                                                    <div className="review-section">
                                                                        <div className="review-image-wrapper p-0">
                                                                            {authData && authData.personalInfo && authData.personalInfo.profilePicture ?
                                                                                <div style={{ backgroundImage: "url(" + authData.personalInfo.profilePicture + ")" }} className="review-image" /> :
                                                                                <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="review-image" />}
                                                                        </div>
                                                                        <div className="review-other-details profile-name-wrapper">
                                                                            <div className="profile-name">{authData && authData.personalInfo && authData.personalInfo.name}</div>
                                                                            <div className="profile-designation">{getDisplayFullDateFormat(new Date(selectedProfile.ratingData.dateTime))}</div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className='review-text-wrapper text-left'>
                                                                            {selectedProfile.ratingData.rating ?
                                                                                stars.map((star, starIndex) => {
                                                                                    return <i key={starIndex} className={`fa-star fas`} style={{ color: star.selected ? "#E12936" : "#ccc" }}></i>
                                                                                }) : null}
                                                                                {bookingDetails.ratingData.feedback ?
                                                                            <div>{selectedProfile.ratingData.feedback.split('\n').map((item, key) => {
                                                                                return <span key={key}>{item}<br /></span>
                                                                            })}</div> : null}
                                                                        </div>
                                                                    </div>}
                                                            </div> </> : null}
                                                        
                                                    </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="text-center well col-12 color-black pt-3">
                        loading...
                    </div>}
            </div>
            {selectedProfile.descriptionImages && openGalleryModal ?
                <GalleryModal openGalleryModal={openGalleryModal} galleryImages={selectedProfile.descriptionImages} currentImageIndex={currentImageIndex} toggleGalleryModal={toggleGalleryModal} updateCurrentImageIndex={updateCurrentImageIndex} /> : null}
        </MuiPickersUtilsProvider>
    )
};

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Details)
);