import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { connect, shallowEqual, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../store/ducks/auth.duck";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import { LayoutContextProvider } from "../../_metronic";
import * as routerHelpers from "../router/RouterHelpers";
import "./navigation.css";
import { requestLoginOtp, confirmLoginOtp, getProfile, updateProfile, removePhoto, requestEmailOtp, confirmEmailOtp, deleteAccountApi } from "../crud/auth.crud";
import { helpSupport, getTutorial } from "../crud/home.crud";
import { REQUIRED_ERROR, VALID_PHONE_ERROR, API_ERROR, INVALID_EMAIL } from "../config/error-message";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { toast } from 'react-toastify';
import { API_SUCCESS } from "../config/success-message";
import { urls, localGlobalConfigData } from "../config/constants";
import LogoutModal from "./logout-modal";
import PromoModal from "./promo-code-modal";
import OpenAppSuggestionModal from "./open-app-modal";
import TutorialModal from '../pages/admins/tutorial-modal';
import OtpModal from './otp-modal';
import BillingModal from "./billing-modal";

const closeImage = require('../../images/modal-close-icon.png');

const dummyProfile = {
  name: "",
  email: "",
  profilePicture: "",
  phoneNumber: "",
  dob: "",
  gender: 1,
  isSchoolGoingChild: false
};

const initialProfileError = {
  name: "",
  phoneNumber: "",
  email: ""
};

const today = new Date();
const yesterday = new Date(today);
const initialDob = new Date(yesterday.setFullYear(yesterday.getFullYear() - 4));
let refreshNeeded = false;
let counterInterval;

const Routes = withRouter(({ history, ...props }) => {
  const [phoneNumber, updatePhoneNumber] = useState("");
  const [phoneNumberError, updatePhoneError] = useState("");
  const [isLoginTermChecked, changeCheckbox] = useState(false);
  const [otpDetails, updateOtpDetails] = useState({});
  const [otpText, updateOtp] = useState('');
  const [menuOpened, toggleMenu] = useState(false);
  const [loginOpened, toggleLoginPopup] = useState(false);
  const [profileOpened, toggleProfile] = useState(false);
  const [promoOpened, togglePromoModal] = useState(false);
  const [isMenuCollapsed, toggleCollapse] = useState(false);
  const [displayModal, toggleModal] = useState(false);
  const [displayHelpModal, toggleHelpModal] = useState(false);
  const [displayLoginOtp, toggleLogin] = useState(false);
  const [displayProfileModal, openProfileModal] = useState(false);
  const [displayBillingModal, openBillingModal] = useState(false);
  const [profileDetails, updateProfileDetails] = useState({ ...dummyProfile });
  const [selectedDate, handleDateChange] = useState(initialDob);
  const [profileDetailsError, updateProfileDetailsError] = useState({ ...initialProfileError });
  const [disablePage, toggleDisablePage] = useState(false);
  const [uploadedProfileImage, updateProfileImage] = useState("");
  const [currentPage, updateCurrentPage] = useState("/");
  const [helpObject, updateHelpObject] = useState({
    description: "",
    type: 1
  });
  const [uploadedHelpImage, updateHelpImage] = useState("");
  const [displayLogoutModal, toggleLogoutModal] = useState(false);
  const [displayDeleteAccountModal, toggleDeleteAccountModal] = useState(false);
  const [displaySuggestionModal, toggleSuggestionModal] = useState(false);
  const [showDobInfo, toggleDobInfo] = useState(false);
  const [showInfo, toggleInfoModal] = useState(false);
  const [tutorials, updateTutorials] = useState([]);
  const [verifyEmailModalOpened, toggleVerifyEmailModal] = useState(false);
  const [disableResend, toggleDisableResend] = useState(false);
  const [resendCounter, updateResendCounter] = useState(30);

  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation, authData } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
      userLastLocation: routerHelpers.getLastLocation(),
      authData: auth.user
    }),
    shallowEqual
  );

  const getFullDateFormat = (date) => {
    const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const openMenu = (bool) => {
    toggleMenu(bool);
    toggleProfile(false);
    toggleLoginPopup(false);
  };

  const openLogin = (bool) => {
    toggleLoginPopup(bool);
    toggleMenu(false);
    toggleProfile(false);
  };

  const openProfile = (bool) => {
    toggleProfile(bool);
    toggleMenu(false);
    toggleLoginPopup(false);
  };

  const openCollapseMenu = (bool) => {
    toggleCollapse(bool);
    toggleMenu(false);
    toggleProfile(false);
    toggleLoginPopup(false);
  };

  const openPage = (path) => {
    const urlParams = new URLSearchParams(window.location.search);
    const catParam = urlParams.get('category');
    const langParam = urlParams.get('language');
    const catIdParam = urlParams.get('categoryId');

    if (path === "/problem-area") {
      let localPath = `${path}?`;
      if (catParam) {
        localPath += `category=${catParam}&`;
      }
      if (langParam) {
        localPath += `language=${langParam}`;
      }
      history.push(localPath);
    } else if (path === "/book-now") {
      sessionStorage.removeItem("vendorFilter");
      let localPath = `${path}?`;
      if (catIdParam) {
        localPath += `categoryId=${catIdParam}`;
      }
      history.push(localPath);
    } else if (path === "/assessment-list") {
      let localPath = `${path}?`;
      const testCatId = urlParams.get('testCategoryId');
      const testCatName = urlParams.get('testCategory');
      if (testCatId) {
        localPath += `testCategoryId=${testCatId}&`;
      }
      if (testCatName) {
        localPath += `testCategory=${testCatName}`;
      }
      history.push(localPath);
    } else {
      history.push(path);
    }

    openMenu(false);
    openProfile(false);
    updateCurrentPage(path);
    if (window.innerWidth < 768) {
      openCollapseMenu(false);
    }
  };

  useEffect(() => {
    if (!window.location?.pathname?.includes("/forum") &&
      !window.location?.pathname?.includes("/faq") &&
      !window.location?.pathname?.includes("/forum-details") &&
      !window.location?.pathname?.includes("/assessment-list") &&
      !window.location?.pathname?.includes("/assessment-details") &&
      !window.location?.pathname?.includes("/book-now") &&
      !window.location?.pathname?.includes("/problem-area") &&
      !window.location?.pathname?.includes("/problem-details") &&
      !window.location?.pathname?.includes("/professional-details") &&
      !window.location?.pathname?.includes("/workshop-details")) {
      document.title = "Mental Health India, Consult Counsellor, Psychologist, More";
    }
    window.onclick = (el) => {
      if (el.target &&
        el.target.className &&
        el.target.className.indexOf &&
        (el.target.className.indexOf('dropdown-toggle') <= -1 &&
          el.target.className.indexOf('profile-image') <= -1) &&
        el.target.className.indexOf('profile-image-drop-icon') <= -1) {
        openMenu(false);
        openProfile(false);
        openLogin(false);
      }
    }
  });

  const clickLogin = () => {
    toggleModal(true);
    toggleLogin(false);
    updateOtp('');
    updatePhoneNumber("");
    changeCheckbox(false);
  };

  const validatePhone = () => {
    if (!phoneNumber.length) {
      updatePhoneError(REQUIRED_ERROR);
      return false;
    }
    if (phoneNumber.length < 10) {
      updatePhoneError(VALID_PHONE_ERROR);
      return false;
    }
    updatePhoneError("");
    return true;
  };

  const getOtp = async () => {
    if (validatePhone()) {
      toggleDisablePage(true);
      const result = await requestLoginOtp({
        "phoneNumber": phoneNumber,
        "countryCode": "IN",
        "dialCode": "+91"
      });
      toggleDisablePage(false);
      if (result.data.errorCode) {
        toast.error(API_ERROR[result.data.errorCode]);
        return;
      }
      if (result.data.data) {
        updateOtpDetails({ ...result.data.data });
        toggleLogin(true);
        toggleDisableResend(true);

        counterInterval = setInterval(setCounter, 1000);
        if (result.data.data.otp) {
          toast.info(`Your otp is: ${result.data.data.otp}`);
        }
      }
    }
  };

  const confirmOtp = async () => {
    toggleDisablePage(true);
    const result = await confirmLoginOtp({
      customerAuthId: otpDetails.customerAuthId,
      otp: otpText
    });
    toggleDisablePage(false);
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      return;
    }
    toast.success(API_SUCCESS.LOGIN_SUCCESS);
    props.login(result.data.data);
    toggleModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const setCounter = () => {
    updateResendCounter(counter => counter - 1);
  };

  useEffect(() => {
    if (resendCounter === 0) {
      clearInterval(counterInterval);
      updateResendCounter(30);
      toggleDisableResend(false);
    }
  }, [resendCounter]);

  const resendOtp = () => {
    getOtp();
    updateOtp("");
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const updateProfileModal = useCallback(async (bool) => {
    if (bool) {
      toggleDisablePage(true);
      const profileResult = await getProfile();
      toggleDisablePage(false);
      if (profileResult.data.errorCode) {
        toast.error(API_ERROR[profileResult.data.errorCode]);
        return;
      }
      if (profileResult.data.data && profileResult.data.data.errorCode) {
        toast.error(API_ERROR[profileResult.data.data.errorCode]);
        return;
      }
      const localProfileDetails = {};
      if (profileResult.data.data && profileResult.data.data.personalInfo) {
        localProfileDetails.emailVerified = profileResult.data.data.emailVerified;
        localProfileDetails.email = profileResult.data.data.personalInfo.email;
        localProfileDetails.phoneNumber = profileResult.data.data.personalInfo.phone.number;
        // if (type !== "emailVerify") {
        localProfileDetails.name = profileResult.data.data.personalInfo.name;
        localProfileDetails.profilePicture = profileResult.data.data.personalInfo.profilePicture;
        localProfileDetails.gender = 0;
        localProfileDetails.hasChildren = profileResult.data.data.hasChildren ? profileResult.data.data.hasChildren : false;
        if (profileResult.data.data.personalInfo.gender) {
          localProfileDetails.gender = profileResult.data.data.personalInfo.gender;
        }
        if (profileResult.data.data.personalInfo.dob) {
          handleDateChange(new Date(profileResult.data.data.personalInfo.dob));
        }
        // }
      }
      updateProfileDetails({ ...localProfileDetails });
      props.updateProfile({ ...profileResult.data.data });
      openProfileModal(bool);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openBillingInfo = useCallback(async (bool) => {
    if (bool) {

      openBillingModal(bool);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateProfile = () => {
    let noError = true;
    const localProfileError = { ...profileDetailsError };

    for (let item in localProfileError) {
      if (item !== "gender") {
        if (!profileDetails[item].trim().length) {
          localProfileError[item] = REQUIRED_ERROR;
          noError = false;
        } else {
          localProfileError[item] = "";
          if (item === "phoneNumber" && profileDetails.phoneNumber.length < 10) {
            localProfileError.phoneNumber = VALID_PHONE_ERROR;
            noError = false;
          }
        }
      }
      if (item === "email" && profileDetails[item].trim().length) {
        if (!validateEmail(profileDetails[item])) {
          localProfileError[item] = INVALID_EMAIL;
          noError = false;
        } else {
          localProfileError[item] = "";
        }
      }
    }
    if (noError && !profileDetails.emailVerified) {
      toast.error("Please verify your email");
      noError = false;
    }

    updateProfileDetailsError({ ...localProfileError });
    return noError;
  };

  const updateProfileData = async () => {
    if (!validateProfile()) {
      return;
    }
    const requestBody = {
      name: profileDetails.name,
      email: profileDetails.email,
      phoneNumber: profileDetails.phoneNumber,
      countryCode: "IN",
      dialCode: "+91",
      hasChildren: profileDetails.hasChildren
    };
    let imageFile = "";
    if (selectedDate) {
      requestBody.dob = getFullDateFormat(new Date(selectedDate));
    }
    if (profileDetails.gender) {
      requestBody.gender = profileDetails.gender.toString();
    }

    imageFile = document.getElementById("profileImage");
    if (imageFile.files[0]) {
      if (imageFile.files[0].type.indexOf("image") > -1) {
        updateProfileImage(URL.createObjectURL(imageFile.files[0]));
      } else {
        toast.error("Please upload image file");
        return;
      }
    }
    //  return;
    toggleDisablePage(true);
    const result = await updateProfile(requestBody, imageFile.files[0]);

    toggleDisablePage(false);
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      return;
    }
    if (result.data.data && result.data.data.errorCode) {
      toast.error(API_ERROR[result.data.data.errorCode]);
      return;
    }
    toast.success(API_SUCCESS.UPDATE_PROFILE);
    openProfileModal(false);
    props.updateProfile({ ...result.data.data });

  };

  const changeInput = (text, fieldName) => {
    const localProfileDetails = { ...profileDetails };
    const localProfileError = { ...profileDetailsError };
    const alphaRegex = /^[a-zA-Z ]*$/;
    if (fieldName === "name") {
      if (alphaRegex.test(text)) {
        localProfileDetails[fieldName] = text;
        localProfileError[fieldName] = "";
      }
    } else {
      localProfileDetails[fieldName] = text;
      localProfileError[fieldName] = "";
    }
    updateProfileDetails({ ...localProfileDetails });
    updateProfileDetailsError({ ...localProfileError });
  };

  const detectMob = () => {
    const toMatch = [
      /Android/i,
      /iPhone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };

  useEffect(() => {
    // if (!window.location.href.includes('www')) {
    //   window.location.href = 'https://www.' + window.location.href.split("https://")[1];
    // }
    if (history.location.pathname !== "/success-payment" && history.location.pathname !== "/failure-payment" &&
      history.location.pathname !== "/success-mobile-payment" && history.location.pathname !== "/failure-mobile-payment" &&
      history.location.pathname !== "/success-payment-event" && history.location.pathname !== "/failure-payment-event" &&
      history.location.pathname !== "/success-payment-workshop" && history.location.pathname !== "/failure-payment-workshop" &&
      history.location.pathname !== "/success-payment-online-event" && history.location.pathname !== "/failure-payment-online-event" && history.location.pathname !== "/privacy-policy" && history.location.pathname !== "/terms-and-conditions" && history.location.pathname !== "/cancellation-policies") {
      const el = document.getElementsByClassName("custom-navigation-bar")[0];
      const el1 = document.getElementsByClassName("footer")[0];
      if (el) {
        el.style.visibility = "visible";
        el.style.display = "block";
      }

      if (el1) {
        el1.style.visibility = "visible";
        el.style.display = "block";
      }
    }
    for (const i in urls) {
      if (history.location.pathname.indexOf(i) > -1) {
        updateCurrentPage(urls[i]);
      }
    }
    if (isAuthorized) {
      if (authData && !authData.emailVerified) {
        updateProfileModal(true);
      }
    }
    // app suggestion
    if (detectMob()) {
      if (!window.sessionStorage.isFirstTime) {
        if (history.location.pathname !== "/privacy-policy" && history.location.pathname !== "/terms-and-conditions" && history.location.pathname !== "/cancellation-policies") {
          window.sessionStorage.setItem("isFirstTime", JSON.stringify(true));
          toggleSuggestionModal(true);
        }
      } else {
        if (JSON.parse(window.sessionStorage.isFirstTime)) {
          window.sessionStorage.setItem("isFirstTime", JSON.stringify(false));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, updateProfileModal, history.location.pathname]);

  const openFileUploader = (imageId) => {
    if (document.getElementById(imageId)) {
      document.getElementById(imageId).click();
    }
  };

  const uploadProfileImage = async () => {
    if (document.getElementById("profileImage")) {
      let imageFile = document.getElementById("profileImage");
      if (imageFile.files[0]) {
        if (imageFile.files[0].type.indexOf("image") > -1) {
          updateProfileImage(URL.createObjectURL(imageFile.files[0]));
        } else {
          toast.error("Please upload image file");
          return;
        }
      }
    }
  };

  const logout = () => {
    toggleLogoutModal(false);
    props.logout();
    setTimeout(() => {
      history.push("/");
      window.location.reload();
    }, 500);

  };

  const deleteAccount = async () => {
    toggleDisablePage(true);
    const result = await deleteAccountApi();
    toggleDisablePage(false);
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      return;
    }
    toast.success(API_SUCCESS.ACCOUNT_DELETE);
    toggleDeleteAccountModal(false);
    setTimeout(() => {
      logout();
    }, 100);
  };

  const updateChildren = () => {
    const localProfileDetails = { ...profileDetails };
    localProfileDetails.hasChildren = !localProfileDetails.hasChildren;
    updateProfileDetails({ ...localProfileDetails });
  };

  const changeHelpDescription = (text) => {
    const localHelp = { ...helpObject };
    localHelp.description = text;
    updateHelpObject({ ...localHelp });
  };

  const changeHelpType = (type) => {
    const localHelp = { ...helpObject };
    localHelp.type = type;
    updateHelpObject({ ...localHelp });
  };

  const uploadHelpImage = async () => {
    if (document.getElementById("helpImage")) {
      let imageFile = document.getElementById("helpImage");
      if (imageFile.files[0]) {
        if (imageFile.files[0].type.indexOf("image") > -1) {
          updateHelpImage(imageFile.files[0].name);
        } else {
          toast.error("Please upload image file");
          return;
        }
      }
    }
  };

  const resetHelpForm = () => {
    updateHelpObject({
      description: "",
      type: 1
    });
    updateHelpImage("");
    let imageFile = "";

    imageFile = document.getElementById("helpImage");
    imageFile.value = '';
  };

  const submitHelp = async () => {
    let imageFile = "";

    imageFile = document.getElementById("helpImage");
    if (imageFile.files[0]) {
      if (imageFile.files[0].type.indexOf("image") > -1) {
        updateHelpImage(URL.createObjectURL(imageFile.files[0]));
      } else {
        toast.error("Please upload image file");
        return;
      }
    }
    //  return;
    toggleDisablePage(true);
    const result = await helpSupport(helpObject, imageFile.files[0]);

    toggleDisablePage(false);
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      return;
    }
    if (result.data.data && result.data.data.errorCode) {
      toast.error(API_ERROR[result.data.data.errorCode]);
      return;
    }
    if (result.data && result.data.success) {
      toast.success(API_SUCCESS.COMPLAIN_SUBMITTED);
    }
    toggleHelpModal(false);
    resetHelpForm();
  };

  const removeProfilePicture = async () => {
    toggleDisablePage(true);
    const result = await removePhoto();
    toggleDisablePage(false);
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      return;
    }

    toast.success(API_SUCCESS.PROFILE_IMAGE_REMOVED);
    if (document.getElementById("profileImageId")) {
      document.getElementById("profileImageId").style.backgroundImage = "url(" + require("../../images/imgAvtr.png") + ")";
    }
    if (document.getElementById("profileImage")) {
      document.getElementById("profileImage").value = "";
    }
    updateProfileModal(true);
  };

  const openInfo = () => {
    toggleDobInfo(!showDobInfo);
  };

  const updateProfileForPromo = async () => {
    const profileResult = await getProfile();
    props.updateProfile({ ...profileResult.data.data });

    if (window.location.pathname.includes("professional-details") || window.location.pathname.includes("event-details") ||
      window.location.pathname.includes("event-list")) {
      refreshNeeded = true;
    }
  };

  useEffect(() => {
    if (!promoOpened && refreshNeeded) {
      window.location.reload();
    }
  }, [promoOpened]);

  const isFullWapper = () => {
    if (window.location.pathname === '/' ||
      window.location.pathname === '/privacy-policy' ||
      window.location.pathname === '/terms-and-conditions' ||
      window.location.pathname === '/cancellation-policies') {

      return true;
    }

    return false;
  };

  const isHome = () => {
    if (window.location.pathname === '/') {

      return true;
    }

    return false;
  };

  const openInfoModal = async () => {
    toggleDisablePage(true);
    const tutorialsResult = await getTutorial();
    toggleDisablePage(false);
    if (tutorialsResult.data.errorCode) {
      toast.error(API_ERROR[tutorialsResult.data.errorCode]);
      return;
    }

    if (tutorialsResult.data && tutorialsResult.data.data && tutorialsResult.data.data.errorCode) {
      toast.error(API_ERROR[tutorialsResult.data.data.errorCode]);
      return;
    }
    if (tutorialsResult.data.data && tutorialsResult.data.data.data && tutorialsResult.data.data.data.length) {
      updateTutorials([...tutorialsResult.data.data.data]);
      toggleInfoModal(true);
    }
  };

  const openVerifyEmailModal = async (isFromModal) => {
    toggleDisablePage(true);
    const result = await requestEmailOtp({
      "email": profileDetails.email
    });
    toggleDisablePage(false);
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      return;
    }
    if (!isFromModal && result.data.success) {
      toggleVerifyEmailModal(true);
    }
    toast.success(API_SUCCESS.EMAIL_SENT);
  };
  const verifyOtp = async (otp) => {
    toggleDisablePage(true);
    const result = await confirmEmailOtp({
      "otp": otp
    });
    toggleDisablePage(false);
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      return;
    }
    if (result.data.success) {
      toast.success(API_SUCCESS.EMAIL_VERIFIED);
      toggleVerifyEmailModal(false);

      updateProfileDetails({ ...profileDetails, emailVerified: true });
    }
  };

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {verifyEmailModalOpened ?
          <OtpModal displayModal={verifyEmailModalOpened}
            toggleModal={toggleVerifyEmailModal} verifyOtp={verifyOtp} resend={openVerifyEmailModal} /> : null}
        <div>
          {showInfo ?
            <TutorialModal displayModal={showInfo} toggleInfoModal={toggleInfoModal} tutorials={tutorials} /> : null}
          <div className={`col-md-12 custom-navigation-bar pad-0-below-768 ${disablePage ? "pointer-none" : ""}`}>
            <nav className="navbar navbar-expand-md navbar-light custom-left-navigation-bar pad-0-below-768">
              <span className="navbar-brand">
                <img src={require("../../images/nav-logo.webp")} className="nav-logo cursor-pointer" alt="nav logo" onClick={() => openPage('/')} />
              </span>
              <span className="show-less-than-768 info-icon-class">
                <i className="fa fa-info-circle cursor-pointer" aria-hidden="true" onClick={() => openInfoModal()}></i></span>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" onClick={() => openCollapseMenu(!isMenuCollapsed)}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`collapse navbar-collapse custom-nav-bar ${isMenuCollapsed ? 'show' : ''}`} id="navbarNavDropdown">
                <ul className="navbar-nav ml-auto margin-top-less-than-768">
                  <li className="nav-item border-top-less-than-768 show-more-than-768 line-height-5">
                    <i className="fa fa-info-circle cursor-pointer mt-2 open-info-icon" aria-hidden="true" onClick={() => openInfoModal()}></i>
                  </li>

                  <li className={`nav-item border-top-less-than-768 ${currentPage === '/' ? 'active' : ''}`}>
                    <span className="nav-link" onClick={() => openPage('/')}>HOME <span className="sr-only">(current)</span></span>
                  </li>
                  <li className={`nav-item border-top-less-than-768 ${currentPage === '/problem-area' ? 'active' : ''}`}>
                    <span className="nav-link" onClick={() => openPage('/problem-area?category=60883f8d1069d58676cb0671')}>PROBLEM AREAS</span>
                  </li>
                  <li className={`nav-item border-top-less-than-768 ${currentPage === '/assessment-list' ? 'active' : ''}`}>
                    <span className="nav-link" onClick={() => openPage('/assessment-list')}>SELF ASSESSMENTS</span>
                  </li>
                  <li className={`nav-item border-top-less-than-768 ${currentPage === '/book-now' ? 'active' : ''}`}>
                    <span className="nav-link" onClick={() => openPage('/book-now?categoryId=605088be5848fb3bf301e90b')}>BOOK NOW</span>
                  </li>

                  {isAuthorized ?
                    <li className={`nav-item border-top-less-than-768 show-more-than-768 dropdown ${menuOpened ? 'show' : ''} ${currentPage === '/booking-list' || currentPage === '/session-bookings' ? 'active' : ''}`}>
                      <span className="nav-link dropdown-toggle" id="myBookings" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => openMenu(!menuOpened)}>
                        MY BOOKINGS
                      </span>
                      <div className={`dropdown-menu left-minus-100 ${menuOpened ? 'show' : ''}`} aria-labelledby="myBookings">
                        <span className={`dropdown-item`} onClick={() => openPage('/booking-list')}>APPOINTMENTS</span>
                        {localGlobalConfigData.showWorkshop ?
                          <span className={`dropdown-item`} onClick={() => openPage('/workshop-booking-list')}>WORKSHOPS</span> : null}
                        <span className={`dropdown-item`} onClick={() => openPage('/event-booking-list')}>EVENTS</span>
                        <span className={`dropdown-item`} onClick={() => openPage('/assessment-results')}>SELF ASSESSMENTS</span>
                      </div>
                    </li> : null}
                  {!isAuthorized ?
                    <>
                      {/* {window.location.pathname !== '/' ?
                        <li className={`nav-item border-top-less-than-768 show-less-than-768`}>
                          <span className="nav-link" id="login-button" onClick={clickLogin}>LOGIN</span>
                        </li> : */}
                      <>
                        <li className={`nav-item border-top-less-than-768 show-less-than-768`}>
                          <span className="nav-link" id="login-button" onClick={clickLogin}>LOGIN AS CUSTOMER</span>
                        </li>
                        <li className={`nav-item border-top-less-than-768 show-less-than-768`}>
                          <span className="nav-link" onClick={() => window.open('https://professional.parentcraftindia.com/')}>LOGIN AS PROFESSIONAL</span>
                        </li>
                        <li className={`nav-item border-top-less-than-768 show-less-than-768`}>
                          <span className="nav-link" onClick={() => window.open('https://www.institution.parentcraftindia.com/')}>LOGIN AS INSTITUTIONAL</span>
                        </li>
                      </>
                      {/* } */}
                      {/* {window.location.pathname === '/' ? */}
                      <li className={`nav-item border-top-less-than-768 show-more-than-768 dropdown ${loginOpened ? 'show' : ''}`}>
                        <button type="button" id="loginDropdown" className="btn btn-sm btn-primary dropdown-toggle login-dropdown-class" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => openLogin(!loginOpened)}>
                          LOGIN
                        </button>
                        <div className={`dropdown-menu left-minus-100 ${loginOpened ? 'show' : ''}`} aria-labelledby="loginDropdown">
                          <span className={`dropdown-item`} onClick={clickLogin} id="login-button">As Customer</span>
                          {/* {localGlobalConfigData.showWorkshop ? */}
                          <span className={`dropdown-item`} onClick={() => window.open('https://professional.parentcraftindia.com/')}>As Professional</span>
                          {/* : null} */}
                          <span className={`dropdown-item`} onClick={() => window.open('https://www.institution.parentcraftindia.com/')}>As Institution</span>
                        </div>
                      </li>
                      {/* :
                        <li className="nav-item border-top-less-than-768 show-more-than-768 login-button">
                          <button type="button" id="login-button" className="btn btn-sm btn-primary" data-toggle="modal" data-target="#exampleModalCenter" onClick={clickLogin}>
                            LOGIN
                          </button>
                        </li>} */}
                    </> :
                    <>
                      <li className={`nav-item border-top-less-than-768 show-less-than-768 ${currentPage === '/booking-list' ? 'active' : ''}`}>
                        <span className="nav-link" onClick={() => openPage('/booking-list')}>APPOINTMENTS</span>
                      </li>
                      {localGlobalConfigData.showWorkshop ?
                        <li className={`nav-item border-top-less-than-768 show-less-than-768 ${currentPage === '/workshop-booking-list' ? 'active' : ''}`}>
                          <span className="nav-link" onClick={() => openPage('/workshop-booking-list')}>WORKSHOPS</span>
                        </li> : null}
                      <li className={`nav-item border-top-less-than-768 show-less-than-768 ${currentPage === '/event-booking-list' ? 'active' : ''}`}>
                        <span className="nav-link" onClick={() => openPage('/event-booking-list')}>EVENTS</span>
                      </li>
                      <li className={`nav-item border-top-less-than-768 show-less-than-768 ${currentPage === '/assessment-results' ? 'active' : ''}`}>
                        <span className="nav-link" onClick={() => openPage('/assessment-results')}>SELF ASSESSMENTS</span>
                      </li>
                      <li className="nav-item border-top-less-than-768 show-less-than-768">
                        <span className="nav-link" id="profile-button" onClick={() => updateProfileModal(true)}>PROFILE</span>
                      </li>
                      
                      <li className="nav-item border-top-less-than-768 show-less-than-768">
                        <span className="nav-link" onClick={() => openPage('/my-favourite')}>MY FAVOURITES</span>
                      </li>
                      <li className="nav-item border-top-less-than-768 show-less-than-768">
                        <span className="nav-link" onClick={() => togglePromoModal(true)}>PREFERENCE CODE</span>
                      </li>
                      <li className="nav-item border-top-less-than-768 show-less-than-768">
                        <span className="nav-link" id="billing-button" onClick={() => openBillingInfo(true)}>BILLING INFO</span>
                      </li>
                      <li className="nav-item border-top-less-than-768 show-less-than-768">
                        <span className="nav-link" onClick={() => openPage('/notifications')}>NOTIFICATIONS</span>
                      </li>
                      <li className="nav-item border-top-less-than-768 show-less-than-768">
                        <span className="nav-link" onClick={() => toggleHelpModal(true)}>HELP & SUPPORT</span>
                      </li>
                      {/* <li className="nav-item border-top-less-than-768 show-less-than-768">
                        <span className="nav-link" onClick={() => toggleDeleteAccountModal(true)}>DELETE ACCOUNT</span>
                      </li> */}
                      <li className="nav-item border-top-less-than-768 show-less-than-768">
                        <span className="nav-link" onClick={() => toggleLogoutModal(true)}>LOGOUT</span>
                      </li>
                      <li className={`nav-item border-top-less-than-768 show-more-than-768 dropdown ${profileOpened ? 'show' : ''}`}>
                        <div className="p-0 profile-image-wrapper dropdown-toggle-x" id="profileDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => openProfile(!profileOpened)}>
                          <span className="sr-only">Profile Dropdown</span>
                          {authData.personalInfo && authData.personalInfo.profilePicture ? <>
                            <div className="profile-image" style={{ backgroundImage: "url(" + authData.personalInfo.profilePicture + ")" }} />
                          </> :
                            <div className="profile-image" style={{ backgroundImage: "url(" + require("../../images/imgAvtr.png") + ")" }} />
                          }
                          <div className="icon-div-wrapper">
                            <i class="fas fa-chevron-circle-down profile-image-drop-icon"></i>
                          </div>
                        </div>
                        {/* </span> */}
                        <div className={`dropdown-menu ${profileOpened ? 'show dropdown-menu-opened' : ''}`} aria-labelledby="profileDropdown">
                          <span className="dropdown-item" id="profile-button" onClick={() => updateProfileModal(true)}>PROFILE</span>
                          <span className="dropdown-item" onClick={() => openPage('/my-favourite')}>MY FAVOURITES</span>
                          <span className="dropdown-item" onClick={() => togglePromoModal(true)}>PREFERENCE CODE</span>
                          <span className="dropdown-item" id="billing-button" onClick={() => openBillingInfo(true)}>BILLING INFO</span>
                          <span className="dropdown-item" onClick={() => openPage('/notifications')}>NOTIFICATIONS</span>
                          <span className="dropdown-item" onClick={() => toggleHelpModal(true)}>HELP & SUPPORT</span>
                          {/* <span className="dropdown-item" onClick={() => toggleDeleteAccountModal(true)}>DELETE ACCOUNT</span> */}
                          <span className="dropdown-item" onClick={() => toggleLogoutModal(true)}>LOGOUT</span>
                        </div>
                      </li>
                    </>}
                </ul>

              </div>
            </nav>
          </div>
        </div>
        <div className={`row header-body-wrapper m-0 ${disablePage ? "pointer-none" : ""}`}>
          <div className={`col-lg-12 main-page-wrapper ${isFullWapper() ? 'full-main-wrapper' : ''} ${!isHome() ? 'pt-0' : ''}`}>
            <HomePage userLastLocation={userLastLocation} toggleLoginModal={toggleModal} />
          </div>
        </div>
        {promoOpened ? <PromoModal displayPromoModal={promoOpened}
          togglePromoModal={togglePromoModal} updateProfileForPromo={updateProfileForPromo} authData={authData}></PromoModal> : null}
        {displayLogoutModal ?
          <LogoutModal displayLogoutModal={displayLogoutModal}
            toggleLogoutModal={toggleLogoutModal} logout={logout} /> : null}
        {displayDeleteAccountModal ?
          <LogoutModal displayLogoutModal={displayDeleteAccountModal}
            toggleLogoutModal={toggleDeleteAccountModal} logout={deleteAccount}
            type="deleteAccount" /> : null}
        <div className={`modal custom-login-modal login-modal fade ${displayModal ? 'show show-login-modal' : ''} ${disablePage ? "pointer-none" : ""}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <img src={closeImage} alt="close modal" onClick={() => toggleModal(false)} className="login-modal-close" />
              <div className="modal-body">
                <div className="col-12 login-logo-wrapper">
                  <img src={require("../../images/logo-blue.webp")} className="hw-70" alt="login logo" />
                </div>
                <div className="col-12 text-center">
                  <div className="welcome-text mt-2 mb-2">{!displayLoginOtp ? 'Login' : 'Verify OTP'}</div>
                  <div className="sign-in-text">{!displayLoginOtp ? 'Please login to continue' : 'Please submit the 4 digit OTP sent to your number for verfication'}</div>
                </div>
                {!displayLoginOtp ?
                  <div className="col-12 login-phone-input mb-3">
                    <div className={`input-group ${phoneNumberError ? 'error-border' : ''}`}>
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">+91</span>
                      </div>
                      <input type="text" className="form-control phone-number-input" placeholder="Phone Number" aria-label="Phone Number" aria-describedby="basic-addon1" value={phoneNumber} onChange={e => { updatePhoneError(""); updatePhoneNumber(e.target.value.replace(/\D/, '')) }} maxLength={10} />
                    </div>
                    {phoneNumberError ?
                      <div className="input-error">{phoneNumberError}</div> : null}
                  </div> :
                  <div className="col-12 login-phone-input">

                    <div className="row form-group login-otp-input-wrapper m-0">
                      <input type="text" className="form-control phone-number-input" placeholder="Enter OTP" aria-label="Enter OTP" aria-describedby="basic-addon1" value={otpText} onChange={e => { updateOtp(e.target.value.replace(/\D/, '')) }} maxLength={4} />
                      {/* {otpArray.map((item, index) => {
                        return <div className="col-3 pl-0" key={index}>
                          <input type="text" className="form-control" id={`otp${index}`} value={item} onChange={e => modifyOtp(e.target.value.replace(/\D/, ''), index)} maxLength={1} />
                        </div>
                      })} */}
                    </div>
                  </div>}
                {!displayLoginOtp ?
                  <div className="col-12 accept-checkbox-wrapper">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" checked={isLoginTermChecked} onChange={() => changeCheckbox(!isLoginTermChecked)} />
                      <label className="form-check-label">
                        I agree to the <span onClick={() => window.open("https://www.parentcraftindia.com/terms-and-conditions")}>Terms & Conditions</span> and <span onClick={() => window.open("https://www.parentcraftindia.com/privacy-policy")}>Privacy Policy</span>.
                      </label>
                    </div>
                  </div> : null}
                {!displayLoginOtp ?
                  <div className="col-12 login-button">
                    <button type="button" className="btn btn-primary" onClick={getOtp} disabled={!isLoginTermChecked || !phoneNumber}>Send OTP</button>
                  </div> :
                  <div className="col-12 login-button">
                    <button type="button" className="btn btn-primary" onClick={confirmOtp} disabled={!otpText}>Verify</button>
                    <button type="button" className="btn btn-link" onClick={resendOtp} disabled={disableResend}>Resend OTP</button>
                    {disableResend ? <span className="ml-2"><b>in 00:{resendCounter < 10 ? "0" : ""}{resendCounter} second{resendCounter > 1 ? "s" : ""}</b></span> : null}
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
        <div className={`${disablePage ? "pointer-none" : ""} modal login-modal ask-question-modal fade ${displayHelpModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Help & Support</h5>
              </div>
              <div className="modal-body">
                <div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="help" id="help1" value="Issue" checked={helpObject.type === 1} onChange={() => changeHelpType(1)} />
                    <label className="form-check-label" htmlFor="help1">
                      Issue
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="help" id="help2" value="Suggestions" checked={helpObject.type === 2} onChange={() => changeHelpType(2)} />
                    <label className="form-check-label" htmlFor="help2">
                      Suggestion
                    </label>
                  </div>
                </div>
                <div>
                  <div className="form-group question-input">
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" placeholder={`${helpObject.type === 1 ? 'Need help...' : 'Any suggestion...'}`} value={helpObject.description} onChange={e => changeHelpDescription(e.target.value)}></textarea>
                  </div>
                </div>
                <div>
                  Add image (upload a screenshot of the specific screen on which you {helpObject.type === 1 ? 'are facing an issue' : 'have a suggestion'}.)
                </div>
                <div className="file-uploader" onClick={() => openFileUploader("helpImage")}>
                  <input type="file" id="helpImage" hidden onChange={uploadHelpImage} />
                  <i className="fa fa-upload" aria-hidden="true"></i>
                </div>
                {uploadedHelpImage ?
                  <div className="mt-3 mb-3">{uploadedHelpImage}</div> : null}

                <div className="col-12 login-button">
                  <button type="button" className="btn btn-primary" disabled={!helpObject.description.trim().length} onClick={submitHelp}>Submit</button>
                  <button type="button" className="btn btn-link" onClick={() => { resetHelpForm(); toggleHelpModal(false) }}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${disablePage ? "pointer-none" : ""} modal login-modal ask-question-modal profile-modal fade ${displayProfileModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document" onClick={() => toggleDobInfo(false)}>
            <div className="modal-content">
              {isAuthorized && authData && authData.personalInfo && authData.personalInfo.name && authData.emailVerified ?
                <img src={closeImage} alt="close modal" onClick={() => openProfileModal(false)} className="login-modal-close" /> : null}
              <div className="modal-header">
                <h5 className="modal-title m-6-auto">Profile</h5>
              </div>
              <div className="modal-body">

                {!uploadedProfileImage ?
                  <div className="col-12 p-0 profile-image-wrapper mb-3">
                    {profileDetails.profilePicture ? <>
                      <div className="profile-image" style={{ backgroundImage: "url(" + profileDetails.profilePicture + ")" }}>
                        <div className="file-uploader">
                          <input type="file" id="profileImage" accept="image/*" hidden onChange={uploadProfileImage} />
                          <i className="fa fa-camera" aria-hidden="true" onClick={() => openFileUploader("profileImage")}></i>
                        </div>
                      </div>
                    </> :
                      <div className="profile-image" style={{ backgroundImage: "url(" + require("../../images/imgAvtr.png") + ")" }}>
                        <div className="file-uploader">
                          <input type="file" id="profileImage" accept="image/*" hidden onChange={uploadProfileImage} />
                          <i className="fa fa-camera" aria-hidden="true" onClick={() => openFileUploader("profileImage")}></i>
                        </div>
                      </div>
                    }

                  </div>
                  :
                  <div className="col-12 p-0 profile-image-wrapper mb-3">
                    <div className="profile-image" id="profileImageId" style={{ backgroundImage: "url(" + uploadedProfileImage + ")" }}>
                      <div className="file-uploader">
                        <input type="file" id="profileImage" accept="image/*" hidden onChange={uploadProfileImage} />
                        <i className="fa fa-camera" aria-hidden="true" onClick={() => openFileUploader("profileImage")}></i>
                      </div>
                    </div>
                  </div>
                }
                {profileDetails.profilePicture ?
                  <div className="text-center font-12"><button className="btn btn-link pt-0 pb-0" onClick={removeProfilePicture}>Remove profile photo</button></div> : null}
                <div className="mt-3 mb-3">
                  <div className="text-center profile-phone-number">+91 {profileDetails.phoneNumber}</div>
                </div>
                <div className="mb-3">
                  <input type="text" className={`form-control profile-inputs ${profileDetailsError.name ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Full Name" value={profileDetails.name} onChange={e => changeInput(e.target.value, 'name')} />
                  {profileDetailsError.name ?
                    <div className="input-error">{profileDetailsError.name}</div> : null}
                </div>
                <div className="mb-3">
                  {!profileDetails.emailVerified && profileDetails.email.trim().length ?
                    <button className="btn btn-link p-0 float-right font-12" onClick={() => openVerifyEmailModal(false)}>Verify Email</button> : null}
                  <input type="text" className={`form-control profile-inputs ${profileDetailsError.email ? 'error-border' : ''}`} id="exampleFormControlInput2" placeholder="Email Address" value={profileDetails.email} onChange={e => changeInput(e.target.value, 'email')} disabled={profileDetails.emailVerified} />
                  {profileDetailsError.email ?
                    <div className="input-error">{profileDetailsError.email}</div> : null}
                  {!profileDetails.emailVerified ? <div className='pt-2 please-verify-email-text'>Please verify your email</div> : <div className='pt-2 verified-email-text'>Verified</div>}
                </div>
                <div className="mb-3">
                  <div className="col-12 p-0 birth-day-date-picker">
                    <DatePicker value={selectedDate} onChange={handleDateChange} format="dd-MM-yyyy" maxDate={initialDob} />
                    <div className="custom-tooltip" onClick={e => { e.stopPropagation(); openInfo() }}>
                      <i className="fas fa-info-circle" aria-hidden="true">

                      </i>
                      <span className={`tooltiptext ${showDobInfo ? 'show-tooltiptext' : ''}`} id="myTooltip">Enter date of birth</span>
                    </div>
                  </div>

                </div>
                <div className="mb-3">
                  <div>
                    <label className="form-label">Gender</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="gender" id="gender1" value="Male" checked={profileDetails.gender === 1} onChange={() => changeInput(1, 'gender')} />
                    <label className="form-check-label" htmlFor="gender1">
                      Male
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="gender" id="gender2" value="Female" checked={profileDetails.gender === 2} onChange={() => changeInput(2, 'gender')} />
                    <label className="form-check-label" htmlFor="gender2">
                      Female
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="gender" id="gender3" value="Other" checked={profileDetails.gender === 3} onChange={() => changeInput(3, 'gender')} />
                    <label className="form-check-label" htmlFor="gender3">
                      Other
                    </label>
                  </div>
                </div>
                <div className="col-12 p-0 accept-checkbox-wrapper">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked={profileDetails.hasChildren} onChange={updateChildren} />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      Do you have school going children?
                    </label>
                  </div>
                </div>
                <div className="col-12 p-0 mt-3 text-center">
                  <button type="button" className="btn btn-primary" onClick={updateProfileData}>Save</button>
                  {isAuthorized && authData && authData.personalInfo && authData.personalInfo.name && authData.emailVerified ? null :
                    <><br /><button className="btn btn-link" onClick={logout}> LOGOUT</button></>}
                </div>
              </div>
            </div>
          </div>
        </div>
        {displayBillingModal ?
          <BillingModal
            openBillingModal={openBillingModal}
          /> : null}
        {/* <div className="spacer"></div> */}
        <footer className={`footer ${disablePage ? "pointer-none" : ""}`}>
          <ul className="nav col-12">
            {/* <li className="nav-item">
              <span className="nav-link" onClick={() => history.push('/')}>About Us</span>
            </li> */}
            <li className="nav-item">
              <span className="nav-link" onClick={() => window.open("https://www.parentcraftindia.com/terms-and-conditions")}>Terms of Use</span>
            </li>
            <li className="nav-item">
              <span className="nav-link" onClick={() => window.open("https://www.parentcraftindia.com/privacy-policy")}>Privacy Policy</span>
            </li>
            <li className="nav-item">
              <span className="nav-link" onClick={() => window.open("https://www.parentcraftindia.com/cancellation-policies")}>Cancellation Policy</span>
            </li>
            <li className="nav-item">
              <span className="nav-link" onClick={() => openPage('/faq')}>FAQs</span>
            </li>
          </ul>
          <div className="col-12 copyright-text">
            © 2021 Parentcraft India. All rights reserved.
          </div>
        </footer>
        <OpenAppSuggestionModal displaySuggestionModal={displaySuggestionModal}
          toggleSuggestionModal={toggleSuggestionModal} />
      </MuiPickersUtilsProvider>

    </LayoutContextProvider>
  );
});

const mapStateToProps = state => {
  return { data: state };
};

export default injectIntl(
  connect(
    mapStateToProps,
    auth.actions
  )(Routes)
);
