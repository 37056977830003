import React, { useEffect, useState, useCallback } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./problem-area.css";

import { getWorkshopList, notification } from "../../crud/home.crud";
import { getWorkshopsBookings } from "../../crud/booking.crud";
import {
    Config,
    timeArrConst,
    monthArr,
    convertTwelveToZeroAM
} from "../../config/constants";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";

const timeArr = [...timeArrConst];

const SessionList = (props) => {
    const { isAuthorized, authData } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
            authData: auth.user
        }),
        shallowEqual
    );
    const [upcomingBookings, updateUpcomingBooking] = useState([]);
    const [pastBookings, updatePastBooking] = useState([]);
    const [disablePage, toggleDisablePage] = useState(false);
    const [loadingUpcomingBooking, toggleLoadingUpcomingBooking] = useState(true);
    const [loadingPastBooking, toggleLoadingPastBooking] = useState(true);
    const [sortDown, toggleSort] = useState({});
    const [popUpOpened, openSortPopup] = useState(false);
    const [currentTab, updateCurrentTab] = useState("upcoming");
    const [hasWorkshop, updateHasWorkshop] = useState(false);

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const getDisplayFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();

        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return convertTwelveToZeroAM(timeArr[times[0] - 1]);
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return convertTwelveToZeroAM(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0]);
    };

    const getTime = (timeStr, date) => {
        const time = timeStr.slice(0, timeStr.length - 3)
        const amPm = timeStr.slice(timeStr.length - 2)
        let d = new Date();
        if (date) {
            d = new Date(date);
        }
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    };

    const checkListDisplayTime = useCallback((localBookingData) => {
        const today = new Date().getTime();
        const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);

        const lastDateDay = new Date(localBookingData.dates[localBookingData.dates.length - 1]).getDate();
        const lastDateMonth = new Date(localBookingData.dates[localBookingData.dates.length - 1]).getMonth();
        const lastDateYear = new Date(localBookingData.dates[localBookingData.dates.length - 1]).getFullYear();

        const lastDateEndTime = new Date(new Date(new Date(endTime).setDate(lastDateDay)).setMonth(lastDateMonth)).setFullYear(lastDateYear);

        return (lastDateEndTime > today);
    }, []);

    const changeSort = (sortConfig) => {
        toggleSort(sortConfig);
        let localSortConfig = {};
        if (sortConfig.sortValue !== 0) {
            localSortConfig[sortConfig.sortType] = sortConfig.sortValue;
        } else {
            localSortConfig = undefined;
        }

        getAllUpcomingBookings(localSortConfig);
    };

    const getAllUpcomingBookings = useCallback(async (sortConfig) => {
        const reqBody = {};
        if (isAuthorized) {
            reqBody.customerRef = authData._id;
        }
        toggleDisablePage(true);
        let upcomingBookingResult = null;

        if (!window.location.pathname.includes('booking')) {
            upcomingBookingResult = await getWorkshopList(0, sortConfig, reqBody);
        } else {
            upcomingBookingResult = await getWorkshopsBookings("upcoming");
        }

        toggleDisablePage(false);
        toggleLoadingUpcomingBooking(false);
        if (upcomingBookingResult.data.errorCode) {
            toast.error(API_ERROR[upcomingBookingResult.data.errorCode]);
            return;
        }
        if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.errorCode) {
            toast.error(API_ERROR[upcomingBookingResult.data.data.errorCode]);
            return;
        }

        let globalConfigResult = {};

        if (!window.location.pathname.includes('booking')) {
            globalConfigResult = await notification();
        }

        const localUpcomingBookings = [];
        let responseBookingDetails = {};
        // let sortedArr = [];
        if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.data && upcomingBookingResult.data.data.data.length) {
            let bookingObj = {};
            let convenienceFee = 0;
            let gst = 0;

            if (globalConfigResult?.data?.data) {
            convenienceFee = globalConfigResult.data.data.convenienceFee ? globalConfigResult.data.data.convenienceFee : 0;
            gst = globalConfigResult.data.data.gstPercentage ?
                (convenienceFee) * (globalConfigResult.data.data.gstPercentage / 100) : 0;
            }
            upcomingBookingResult.data.data.data.forEach(each => {
                responseBookingDetails = {};

                if (!window.location.pathname.includes('booking')) {
                    responseBookingDetails = { ...each };
                } else {
                    responseBookingDetails = { ...each.workshopRef };
                }
                if (responseBookingDetails.images?.length) {
                    if (checkListDisplayTime(responseBookingDetails)) {
                        bookingObj = {
                            convenienceFee,
                            gst
                        };

                        bookingObj._id = each._id;
                        bookingObj.vendorName = each.vendorRef.personalInfo.name;
                        bookingObj.vendorId = each.vendorRef._id;
                        bookingObj.profileVisible = each.vendorRef.profileVisible !== undefined ? each.vendorRef.profileVisible : true;
                        bookingObj.title = responseBookingDetails.title;
                        bookingObj.description = responseBookingDetails.description;
                        bookingObj.startDate = getFullDateFormat(new Date(responseBookingDetails.dates[0]));
                        bookingObj.endDate = getFullDateFormat(new Date(responseBookingDetails.dates[responseBookingDetails.dates.length - 1]));
                        bookingObj.slots = responseBookingDetails.slots;
                        bookingObj.startTime = getStartTime(responseBookingDetails.slots);
                        bookingObj.endTime = getEndTime(responseBookingDetails.slots);
                        bookingObj.fee = responseBookingDetails.fees;
                        bookingObj.workshopImage = responseBookingDetails.images[0]?.url;
                        bookingObj.status = each.status;
                        bookingObj.statusColor = each.status === 3 ? "#00A365" : (each.status !== 1 && each.status !== 2) ? "#E52E40" : "#185bdb";
                        if (!window.location.pathname.includes('booking') && globalConfigResult.data.data && globalConfigResult.data.data.promoCodeGeneral && globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn && globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.workshop && each.vendorRef && each.vendorRef.promoCodeInfo && each.vendorRef.promoCodeInfo.allowDiscount && authData && authData.promoCodeInfo && authData.promoCodeInfo.institutionRef) {
                            bookingObj.discountedPrice = bookingObj.fee * ((100 - globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.workshop) / 100);
                        }

                        if (window.location.pathname.includes('booking')) {
                            bookingObj.fee = each.paymentDetails.total;
                        }

                        localUpcomingBookings.push({ ...bookingObj });
                    }
                }
            });
        }

        updateUpcomingBooking([...localUpcomingBookings]);
        if (window.location.pathname.includes('booking') && !localUpcomingBookings.length) {
            const upcomingWorkshops = await getWorkshopList(0, sortConfig, reqBody);
            if (upcomingWorkshops.data.data && upcomingWorkshops.data.data.data && upcomingWorkshops.data.data.data.length) {
                updateHasWorkshop(true);
            }
        }
    }, [isAuthorized, authData, checkListDisplayTime]);

    const getAllPastBookings = useCallback(async () => {
        toggleDisablePage(true);
        const pastBookingResult = await getWorkshopsBookings("past");
        toggleLoadingPastBooking(false);
        toggleDisablePage(false);
        if (pastBookingResult.data.errorCode) {
            toast.error(API_ERROR[pastBookingResult.data.errorCode]);
            return;
        }
        if (pastBookingResult.data.data && pastBookingResult.data.data.errorCode) {
            toast.error(API_ERROR[pastBookingResult.data.data.errorCode]);
            return;
        }

        const localUpcomingBookings = [];
        let responseBookingDetails = {};
        // let sortedArr = [];
        if (pastBookingResult.data.data && pastBookingResult.data.data.data && pastBookingResult.data.data.data.length) {
            let bookingObj = {};
            pastBookingResult.data.data.data.forEach(each => {
                responseBookingDetails = each.workshopRef;

                if (responseBookingDetails.images?.length) {
                    bookingObj = {};

                    bookingObj._id = each._id;
                    bookingObj.vendorName = each.vendorRef.personalInfo.name;
                    bookingObj.vendorId = each.vendorRef._id;
                    bookingObj.profileVisible = each.vendorRef.profileVisible !== undefined ? each.vendorRef.profileVisible : true;
                    bookingObj.title = responseBookingDetails.title;
                    bookingObj.description = responseBookingDetails.description;
                    bookingObj.startDate = getFullDateFormat(new Date(responseBookingDetails.dates[0]));
                    bookingObj.endDate = getFullDateFormat(new Date(responseBookingDetails.dates[responseBookingDetails.dates.length - 1]));
                    if (each.ratingDetails) {
                        bookingObj.bookingRating = each.ratingDetails.rating;
                    }
                    bookingObj.status = each.status;
                    bookingObj.statusColor = each.status === 3 ? "#00A365" : (each.status !== 1 && each.status !== 2) ? "#E52E40" : "#185bdb";
                    bookingObj.slots = responseBookingDetails.slots;
                    bookingObj.startTime = getStartTime(responseBookingDetails.slots);
                    bookingObj.endTime = getEndTime(responseBookingDetails.slots);
                    bookingObj.fee = each.paymentDetails.total;
                    bookingObj.workshopImage = responseBookingDetails.images[0]?.url;

                    localUpcomingBookings.push({ ...bookingObj });
                }
            });
        }

        updatePastBooking([...localUpcomingBookings]);
    }, []);

    const updateUrl = (type) => {
        const localUrl = `/workshop-booking-list?type=${type}`;
        props.history.push(localUrl);
    };

    useEffect(() => {
        getAllUpcomingBookings();
        if (window.location.pathname.includes('booking')) {
            getAllPastBookings();
            const urlParams = new URLSearchParams(window.location.search);
            const typeParam = urlParams.get('type');
            if (typeParam) {
                updateCurrentTab(typeParam);
            } else {
                updateUrl("upcoming");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllUpcomingBookings, window.location.pathname]);

    useEffect(() => {
        window.onclick = (el) => {
            if (el.target &&
                el.target.className &&
                el.target.className.indexOf &&
                el.target.className.indexOf('dropdown-toggle') <= -1) {
                openSortPopup(false);
            }
        }
    });

    const openVendorProfile = (vendorProfile) => {
        if (vendorProfile.profileVisible) {
            props.history.push(`/professional-details/${vendorProfile.vendorId}/${vendorProfile.vendorName.split(" ").join("-")}`, { vendorId: vendorProfile.vendorId });
        }
    };

    const goBack = () => {
        props.history.goBack();
    };

    return (
        <div className={`row problem-area booking-list workshop-list ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12">
                <div className="row" style={{ margin: 0 }}>
                    <div className={`col-${window.location.pathname.includes('booking') ? '6' : '12'} categories-heading`}>
                        <h4 className={`mb-0 problem-category-heading`}>{!window.location.pathname.includes("booking") ? <i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: "10px", cursor: "pointer" }} onClick={goBack}></i> : null}
                            Workshops {window.location.pathname.includes("booking") ? 'Booking' : ''} List {window.location.pathname.includes("booking") ? <i className="fas fa-info-circle ml-3" aria-hidden="true" style={{ fontSize: "18px", cursor: "pointer" }} /> : null}</h4>
                        {upcomingBookings.length && !window.location.pathname.includes("booking") ?
                            <ul className="navbar-nav custom-left-nav">
                                <li className={`nav-item dropdown ${popUpOpened ? 'show' : ''}`}>
                                    <img src={require('../../../images/sort.png')} className="sort-down dropdown-toggle" style={{ width: "40%", right: "20px", paddingRight: "0 !important" }} alt="sort down" onClick={() => openSortPopup(!popUpOpened)} />
                                    <div className={`dropdown-menu ${popUpOpened ? 'show dropdown-menu-opened' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                                        <span className={`dropdown-item ${sortDown.sortType === "" && sortDown.sortValue === 0 ? 'active' : ''}`} onClick={() => changeSort({ sortType: "", sortValue: 0 })}>Sort By Relevance</span>
                                        <span className={`dropdown-item ${sortDown.sortType === "fees" && sortDown.sortValue === -1 ? 'active' : ''}`} onClick={() => changeSort({ sortType: "fees", sortValue: -1 })}>Sort By Price (Desc)</span>
                                        <span className={`dropdown-item ${sortDown.sortType === "fees" && sortDown.sortValue === 1 ? 'active' : ''}`} onClick={() => changeSort({ sortType: "fees", sortValue: 1 })}>Sort By Price (Asc)</span>
                                    </div>
                                </li>
                            </ul> : null}
                    </div>
                    {window.location.pathname.includes('booking') ?
                        <div className="col-6 pr-0 upcoming-past-button-wrapper text-right pt-3">
                            <button onClick={() => { updateCurrentTab("upcoming"); updateUrl("upcoming") }} className={`btn btn-primary upcoming-past-button ${currentTab === 'upcoming' ? 'active' : ''}`}>Upcoming</button>
                            <button onClick={() => { updateCurrentTab("past"); updateUrl("past") }} className={`btn btn-primary upcoming-past-button ${currentTab === 'past' ? 'active' : ''}`}>Past</button>
                        </div>
                        : null}
                </div>
                <div className="row" style={{ margin: 0 }}>
                    {!window.location.pathname.includes('booking') || currentTab === "upcoming" ?
                        <div className="col-12">
                            <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                                {upcomingBookings.length ?
                                    upcomingBookings.map((professional, index) => {
                                        return <div className={`col-12 professional-wrapper ${index !== upcomingBookings.length - 1 ? 'mb-15' : ''}
                                `} key={index}>
                                            <div className={`row booking-item-wrapper`} style={{ margin: 0 }}>
                                                <div className="booking-profile-image workshop-image">
                                                    {professional.workshopImage ?
                                                        <img src={professional.workshopImage} alt="workshop" /> :
                                                        null}
                                                    {!window.location.pathname.includes('booking') ?
                                                        <div className="amount-image-wrapper workshop-mobile-view-only" style={{ backgroundImage: "url(" + require('../../../images/amount-image.png') + ")", width: professional.discountedPrice ? '160px' : '100px', backgroundSize: 'cover', backgroundPosition: 'right' }}> <i className="fas fa-rupee-sign"></i><span className={`${professional.discountedPrice ? 'text-strikethrough' : ''}`}>{(professional.fee + professional.convenienceFee + professional.gst).toFixed(2)}</span> {professional.discountedPrice ? <> <i className="fas fa-rupee-sign ml-2"></i>{(professional.discountedPrice + professional.convenienceFee + professional.gst).toFixed(2)}</> : null} </div> : null}
                                                </div>
                                                <div className="booking-details-wrapper workshop-details-wrapper">
                                                    <div className="col-12 workshop-desktop-view-only">
                                                        <div className="professional-name">{professional.title}</div>
                                                        <div className='mb-3'>Created by <span className={`professional-amount text-underline ${professional.profileVisible ? 'cursor-pointer': ''}`} onClick={() => openVendorProfile(professional)}>{professional.vendorName}</span></div>
                                                        {!window.location.pathname.includes('booking') ? <>
                                                            <div className="amount-image-wrapper mt-2 mb-2" style={{ backgroundImage: "url(" + require('../../../images/amount-image.png') + ")", width: professional.discountedPrice ? '160px' : '100px', backgroundSize: 'cover', backgroundPosition: 'right' }}> <i className="fas fa-rupee-sign"></i><span className={`${professional.discountedPrice ? 'text-strikethrough' : ''}`}>{(professional.fee + professional.convenienceFee + professional.gst).toFixed(2)}</span> {professional.discountedPrice ? <> <i className="fas fa-rupee-sign ml-2"></i>{(professional.discountedPrice + professional.convenienceFee + professional.gst).toFixed(2)}</> : null} </div>
                                                            <div className="professional-service-desc">{professional.description}</div> </> : null}
                                                        <div className="professional-date-time row m-0 booking-item-side-row">
                                                            <div className="col-12 p-0">
                                                                <div><span className="label mr-3">Dates</span>
                                                                    <img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {getDisplayFullDateFormat(new Date(professional.startDate))} {professional.startDate !== professional.endDate ? `- ${getDisplayFullDateFormat(new Date(professional.endDate))}` : ''}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 p-0 mt-1 mb-1">
                                                                <div><span className="label mr-3">Time slot</span><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {professional.startTime} - {professional.endTime}</div>
                                                            </div>
                                                        </div>
                                                        <div className="professional-amount row ml-0 mr-0 booking-item-side-row">
                                                            {window.location.pathname.includes('booking') ?
                                                                <div className='col-8 pl-0 pt-3' style={{
                                                                    color: professional.statusColor
                                                                }}>
                                                                    Status: {Config.Event.NumberStatus[professional.status]}
                                                                </div> : null}
                                                            <div className={`${window.location.pathname.includes('booking') ? 'col-4' : 'col-12'} pr-0`}>
                                                                <button className="btn btn-primary details-button" onClick={() => props.history.push(`/workshop-${window.location.pathname.includes('booking') ? 'booking-' : ''}details/${professional._id}`)}>View Details</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-12 p-0 workshop-mobile-view-only mt-3">
                                                    <div className="professional-name">{professional.title}</div>
                                                    <div>Created by <span className={`professional-amount text-underline ${professional.profileVisible ? 'cursor-pointer': ''}`} onClick={() => openVendorProfile(professional)}>{professional.vendorName}</span></div>
                                                    <div className="professional-date-time row m-0 booking-item-side-row">
                                                        <div className="col-12 p-0 mt-2 mb-2">
                                                            <div><span className="label mr-3">Dates</span>
                                                                <img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {getDisplayFullDateFormat(new Date(professional.startDate))} {professional.startDate !== professional.endDate ? `- ${getDisplayFullDateFormat(new Date(professional.endDate))}` : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0">
                                                            <div><span className="label mr-3">Time slot</span><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {professional.startTime} - {professional.endTime}</div>
                                                        </div>
                                                    </div>
                                                    <div className="professional-amount row ml-0 mr-0 booking-item-side-row">
                                                        <div className='col-6 pl-0' style={{
                                                            color: professional.statusColor
                                                        }}>
                                                            {window.location.pathname.includes('booking') ?
                                                                `Status: ${Config.Event.NumberStatus[professional.status]}`
                                                                : null}
                                                        </div>
                                                        <div className='col-6 pr-0'>
                                                            <button className="btn btn-primary details-button" onClick={() => props.history.push(`/workshop-${window.location.pathname.includes('booking') ? 'booking-' : ''}details/${professional._id}`)}>View Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    : <div className="text-center well col-12 color-black">
                                        {!loadingUpcomingBooking ? window.location.pathname.includes('booking') ? <div className='no-data-found'>
                                            <img src={require("../../../images/no-data.png")} alt="no data" />
                                            <br />
                                            <div className='no-data-header'>No Workshop Found</div>
                                            {hasWorkshop ?
                                                <button className='btn btn-primary no-data-button' onClick={() => props.history.push('/workshop-list')}>View Workshop</button> : null}
                                        </div> : 'No workshop found!' : 'loading...'}
                                    </div>}
                            </div>
                        </div> :
                        <div className="col-12">
                            <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                                {pastBookings.length ?
                                    pastBookings.map((professional, index) => {
                                        return <div className={`col-12 professional-wrapper ${index !== pastBookings.length - 1 ? 'mb-15' : ''}
                            `} key={index}>
                                            <div className={`row booking-item-wrapper`} style={{ margin: 0 }}>
                                                <div className="booking-profile-image workshop-image">
                                                    {professional.workshopImage ?
                                                        <img src={professional.workshopImage} alt="workshop" /> :
                                                        null}
                                                    {!window.location.pathname.includes('booking') ?
                                                        <div className="amount-image-wrapper workshop-mobile-view-only" style={{ backgroundImage: "url(" + require('../../../images/amount-image.png') + ")", width: professional.discountedPrice ? '160px' : '100px', backgroundSize: 'cover', backgroundPosition: 'right' }}> <i className="fas fa-rupee-sign"></i><span className={`${professional.discountedPrice ? 'text-strikethrough' : ''}`}>{professional.fee.toFixed(2)}</span> {professional.discountedPrice ? <> <i className="fas fa-rupee-sign ml-2"></i>{professional.discountedPrice.toFixed(2)}</> : null} </div> : null}
                                                </div>
                                                <div className="booking-details-wrapper workshop-details-wrapper">
                                                    <div className="col-12 workshop-desktop-view-only">
                                                        <div className="professional-name">{professional.title}</div>
                                                        <div className='mb-3'>Created by <span className={`professional-amount text-underline ${professional.profileVisible ? 'cursor-pointer': ''}`} onClick={() => openVendorProfile(professional)}>{professional.vendorName}</span></div>
                                                        {!window.location.pathname.includes('booking') ? <>
                                                            <div className="amount-image-wrapper mt-2 mb-2" style={{ backgroundImage: "url(" + require('../../../images/amount-image.png') + ")", width: professional.discountedPrice ? '160px' : '100px', backgroundSize: 'cover', backgroundPosition: 'right' }}> <i className="fas fa-rupee-sign"></i><span className={`${professional.discountedPrice ? 'text-strikethrough' : ''}`}>{professional.fee.toFixed(2)}</span> {professional.discountedPrice ? <> <i className="fas fa-rupee-sign ml-2"></i>{professional.discountedPrice.toFixed(2)}</> : null} </div>
                                                            <div className="professional-service-desc">{professional.description}</div> </> : null}
                                                        <div className="professional-date-time row m-0 booking-item-side-row">
                                                            <div className="col-12 p-0">
                                                                <div><span className="label mr-3">Dates</span>
                                                                    <img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {getDisplayFullDateFormat(new Date(professional.startDate))} {professional.startDate !== professional.endDate ? `- ${getDisplayFullDateFormat(new Date(professional.endDate))}` : ''}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 p-0 mt-1 mb-1">
                                                                <div><span className="label mr-3">Time slot</span><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {professional.startTime} - {professional.endTime}</div>
                                                            </div>
                                                        </div>
                                                        <div className="professional-amount row ml-0 mr-0 booking-item-side-row">
                                                            <div className={`col-8 pl-0 ${professional.status === 3 ? "" : "pt-3"}`}>
                                                                <div className='pb-1' style={{
                                                                    color: professional.statusColor
                                                                }}>Status: {Config.Event.NumberStatus[professional.status]}</div>
                                                                {professional.status === 3 ?
                                                                    professional.bookingRating ?
                                                                        <div className='rated'>You rated <span className='rating'><span style={{verticalAlign: "middle"}}>{professional.bookingRating.toFixed(1)}</span> <i className={`fa-star fas`}></i>  </span></div> : <button className='btn btn-primary' onClick={() => props.history.push(`/workshop-rating-review/${professional._id}`)}>Rate Now</button>
                                                                    : null}
                                                            </div>
                                                            <div className={`col-4 pr-0 ${professional.status === 3 ? "pt-3" : ""}`}>
                                                                <button className="btn btn-primary details-button" onClick={() => props.history.push(`/workshop-booking-details/${professional._id}`)}>View Details</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 p-0 workshop-mobile-view-only mt-3">
                                                    <div className="professional-name">{professional.title}</div>
                                                    <div>Created by <span className={`professional-amount text-underline ${professional.profileVisible ? 'cursor-pointer': ''}`} onClick={() => openVendorProfile(professional)}>{professional.vendorName}</span></div>
                                                    <div className="professional-date-time row m-0 booking-item-side-row">
                                                        <div className="col-12 p-0 mt-2 mb-2">
                                                            <div><span className="label mr-3">Dates</span>
                                                                <img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {getDisplayFullDateFormat(new Date(professional.startDate))} {professional.startDate !== professional.endDate ? `- ${getDisplayFullDateFormat(new Date(professional.endDate))}` : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0">
                                                            <div><span className="label mr-3">Time slot</span><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {professional.startTime} - {professional.endTime}</div>
                                                        </div>
                                                    </div>
                                                    <div className="professional-amount row ml-0 mr-0 booking-item-side-row">
                                                        <div className={`col-6 pl-0 ${professional.status === 3 ? "" : "pt-3"}`}>
                                                            <div className='pb-1' style={{
                                                                color: professional.statusColor
                                                            }}>Status: {Config.Event.NumberStatus[professional.status]}</div>
                                                            {professional.status === 3 ?
                                                                professional.bookingRating ?
                                                                    <div className='rated'>You rated <span className='rating'><span style={{verticalAlign: "middle"}}>{professional.bookingRating.toFixed(1)}</span> <i className={`fa-star fas`}></i>  </span></div> : <button className='btn btn-primary' onClick={() => props.history.push(`/workshop-rating-review/${professional._id}`)}>Rate Now</button>
                                                                : null}
                                                        </div>
                                                        <div className={`col-6 pr-0 ${professional.status === 3 ? "pt-3" : ""}`}>
                                                            <button className="btn btn-primary details-button" onClick={() => props.history.push(`/workshop-booking-details/${professional._id}`)}>View Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    : <div className="text-center well col-12 color-black">
                                        {!loadingPastBooking ? <div className='no-data-found'>
                                            <img src={require("../../../images/no-data.png")} alt="no data" />
                                            <br />
                                            <div className='no-data-header'>No Workshop Found</div>
                                        </div> : "loading..."}
                                    </div>}
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default SessionList;