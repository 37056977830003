import React, { useEffect, useState } from 'react';
import "../pages/admins/problem-area.css";
import { StateList } from '../config/billing-info-states';
import { updateBillingInfo } from '../crud/auth.crud';
import { API_ERROR } from '../config/error-message';
import { API_SUCCESS } from '../config/success-message';
import { toast } from 'react-toastify';
import { getProfileData } from '../config/common';
const closeImage = require('../../images/modal-close-icon.png');

const BillingModal = (props) => {
  const [selectedStateCode, updateSelectedStateCode] = useState(StateList[0].code);
  const [gst, updateGst] = useState("");
  const [hasStateError, toggleStateError] = useState(false);
  const [disablePage, toggleDisablePage] = useState(false);
 
  const selectState = (e) => {
    toggleStateError(false);
    updateSelectedStateCode(e.target.value);
  };

  const fetchProfile = async () => {
    const res = await getProfileData();
    if (!res.error) {
      if (res.profileData.billingInfo && res.profileData.billingInfo[0] && res.profileData.billingInfo[0].gstStateCode) {
        const localBill = res.profileData.billingInfo[0];
        updateSelectedStateCode(localBill.gstStateCode);
        updateGst(localBill.gstNumber);
      }
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const saveBilling = async () => {
    if (selectedStateCode !== "00") {
      toggleStateError(false);
      const reqBody = {
        "billingInfo": {
            "gstNumber": gst,
            "state": StateList.filter(each => each.code === selectedStateCode)[0].state,
            "gstStateCode": selectedStateCode
        },
        "index": 0
      };
      
      toggleDisablePage(true);
      const result = await updateBillingInfo(reqBody);
      if (result.data.errorCode) {
        toast.error(API_ERROR[result.data.errorCode]);
        return;
      }
      toast.success(API_SUCCESS.UPDATE_BILLING_INFO);
      toggleDisablePage(false);
      props.openBillingModal(false);
    } else {
      toggleStateError(true);
    }
    
  };

  return (
    <div className={`${disablePage ? "pointer-none" : ""} modal login-modal ask-question-modal profile-modal fade show show-login-modal`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md" role="document">
        <div className="modal-content">
          <img src={closeImage} alt="close modal" onClick={() => props.openBillingModal(false)} className="login-modal-close" />
          <div className="modal-header">
            <h5 className="modal-title m-6-auto">Billing Info</h5>
          </div>
          <div className="modal-body">
            <div className="text-center profile-phone-number">Please complete the billing details before booking</div>
            <div className="my-3">
              <label htmlFor="exampleFormControlInput1" className="form-label fee-label all-label">Select State &nbsp;<span className='mandatory-doc-message'>*</span></label>
              <select className="form-select form-control profile-inputs" aria-label="Select State" value={selectedStateCode} onChange={selectState}>
                {StateList.map((val) => {
                  return <option key={val.code} value={val.code}>{val.state}</option>
                })}
              </select>
              {hasStateError ? <div className="input-error">Please choose a state</div> : null}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput2" className="form-label fee-label all-label">GSTIN (Optional)</label>
              <input type="text" className={`form-control profile-inputs`} id="exampleFormControlInput2" placeholder="GSTIN (Optional)" value={gst} onChange={e => updateGst(e.target.value)} />
              
            </div>


            <div className="col-12 p-0 mt-3 text-center">
              <button type="button" className="btn btn-primary" onClick={saveBilling}>Save</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BillingModal;